.btn-container {
    font-size: 15px !important;
    line-height: 18px !important;
    font-weight: 700 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    position: relative !important;
    display: inline-block !important;
    background: #ff1520 !important;
    color: #fff !important;
    -webkit-transition: background 0.4s ease !important;
    -o-transition: background 0.4s ease !important;
    transition: background 0.4s ease !important;
    border-radius: 45px !important;
    padding: 13px 20px !important;
    text-align: center !important;
    width: auto;
    height: auto !important;
    border: none !important;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.text-bold {
    font-weight: bold;
}

.text-white {
    color: #ffffff !important;
}

.text-black {
    color: #294352 !important;
}

.text-red {
    color: #ff1520 !important;
}

ul.list-style-custom,
.delivery-text ul {
    list-style: none;
    margin: 0px;
    margin-left: 22px;
    padding: 0px;
}

ul.list-style-custom li,
.delivery-text ul li {
    position: relative;
    padding: 0px 0px 5px 0px;
}

ul.list-style-custom li::before,
.delivery-text ul li::before {
    content: '';
    display: inline-block;
    position: absolute;
    left: -22px;
    top: 3px;
    padding: 0px;
    margin: 0px;
    width: 15px;
    height: 15px;
    background-color: #FF1520;
    border: 0px;
    border-radius: 50%;
}

.home-purpose-section .single-category a {
    color: #294352;
}

.container-homepage-dimensions-wrapper .homepage-desktop .all-dimension-table table td {
    border: 0px solid #DEE2E6 !important;
}

.container-homepage-dimensions-wrapper .homepage-desktop {
    display: block;
}

.container-homepage-dimensions-wrapper .homepage-mobile {
    display: none;
}

.container-homepage-dimensions-wrapper .homepage-mobile .all-dimension-table table {
    min-width: auto !important;
}

@media (max-width: 1060px) {

    .container-homepage-dimensions-wrapper {
        margin-top: 50px;
    }

    .container-homepage-dimensions-wrapper .homepage-desktop {
        display: none;
    }

    .container-homepage-dimensions-wrapper .homepage-mobile {
        display: block;
    }
}

.container-homepage-dimensions-wrapper {
    margin-top: 30px !important;
}

.removals-menu .removals-left > ul > li > ul li {
    margin-top: 10px;
    margin-bottom: 10px;
}

.all-dimension-table {
    /*max-height: 600px;*/
}

.all-dimension-table table {
    min-width: 1000px;
}

.all-dimension-table table .sub-heading {
    font-size: 14px;
}

.all-dimension-table table td {
    font-size: 12px;
    border: 1px solid #DEE2E6 !important;
    border-bottom: 1px solid #294352 !important;
}


.all-dimension-table table .bg-white {
    background-color: #ffffff;
    border: 1px solid #294352;
    color: #294352 !important;
}

.all-dimension-table table .bg-grey {
    background-color: #f2f2f2 !important;
    border: 1px solid #294352;
    color: #294352 !important;
}

.all-dimension-table table .bg-yellow {
    background-color: #fff192 !important;
    border: 1px solid #fff192;
    color: #294352 !important;
}

.all-dimension-table table .bold-heading {
    font-size: 13px;
    font-weight: bold;
}

.all-dimension-table table .small-heading {
    font-size: 11px;
    font-weight: normal;
}

.all-dimension-table table .border-right-dark {
    border-right: 1px solid #294352 !important;
}

.dimension-container .table-responsive .dimension-table th {
    padding: 5px 2px;
}

.dimension-container .table-responsive .dimension-table td {
    padding-left: 2px;
    padding-right: 2px;
}

.pricing-section.section-container .container .pricing-container.v2 {
    padding: 0 0 0 0;
}

.home-removal-section .removal-links {
    text-align: center;
    margin-top: 30px;
}

.home-removal-section .removal-links ul {
    text-align: center;
    margin: 0 auto;
}

.home-removal-section .removal-links ul li {
    list-style: none;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
}

.home-removal-section .removal-links ul li a {
    color: #294352;
}

#mobile-menu li {
    list-style: none;
}

#mobile-menu a {
    color: #ffffff;
}

@media (max-width: 1199px) {
    #mobile-menu a {
        color: rgb(0, 0, 0, 0.75);
    }

}

.new-home-leader-bottom .block-item img {
    width: 40px;
    height: 40px;
}

.new-product-info-container .product-info-left {
    -ms-flex: 0 0 49%;
    flex: 0 0 49%;
    width: 49%;
}

.new-product-info-container .product-info-right {
    -ms-flex: 0 0 49%;
    flex: 0 0 49%;
    width: 49%;
    -webkit-transform: translateX(5%);
    -ms-transform: translateX(5%);
    transform: translateX(5%);
}

.new-product-info-container.reversed .product-info-right {
    -webkit-transform: translateX(-5%);
    -ms-transform: translateX(-5%);
    transform: translateX(-5%);
}

@media (max-width: 1024px) {
    .new-product-info-container.reversed .product-info-right {
        -webkit-transform: translateX(5%);
        -ms-transform: translateX(5%);
        transform: translateX(5%);
    }
}

.new-product-info-container .product-info-right .product-panel {
    padding-left: 35px;
}

.new-product-info-container.reversed .product-info-right .product-panel {
    padding-right: 35px;
}

@media (max-width: 600px) {
    .hero-section .hero-lead-inner .hero-lead-left .hero-slider .slick-list {
        overflow-x: hidden;
    }

    .hero-section.delivery .hero-lead-inner .hero-lead-left .hero-slider .slick-list {
        overflow-x: hidden;
        top: 5px;
    }

    .hero-section .hero-lead-inner .hero-lead-left .hero-slider .slick-slide > img {
        width: 300px;
    }

    .hero-section .hero-lead-inner .hero-lead-left .hero-slider {
        padding-top: 20px;
        top: -14px;
        position: relative;
    }
    .hero-section .hero-lead-inner .hero-lead-left .hero-slider .hero-slider-item{
        text-align: center;
    }
    .hero-section .hero-lead-inner .hero-lead-left .hero-slider .hero-slider-item img{
        max-height: 179px;
    }

    .hero-section .container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .hero-section .container .home-lead-right {
        padding-left: 20px;
        padding-right: 20px;
    }

    .hero-section .hero-lead-inner .home-lead-right {
        margin-top: 0px;
    }

}

/*Floating Back-To-Top Button*/
#BackToTopBtn {
    position: fixed;
    bottom: 10px;
    float: right;
    right: 10px;
    max-width: 50px;
    width: 50px;
    height: 50px;
    font-size: 15px;
    color: #ffffff;
    border-color: #dddddd;
    background-color: #dddddd;
    padding: 10px;
    border-radius: 5px;
    border: 0px;
    transition: all 0.3s ease-in;
}

#BackToTopBtn:hover {
    background-color: #ff1520;
    transform: translateY(-5px);
}

/* price guide page */
.price-guide-table tr th {
    border: 1px solid #ffffff !important;
}

.price-table-header {
    font-size: 20px;
    font-weight: 700;
    margin-top: 50px;
    margin-bottom: 20px;
}

.container-dimension {
    margin-top: 50px;
}

.dimension-container.introduction {
    padding: 0px 100px;
}

@media (min-width: 481px) and (max-width: 768px) {
    .dimension-container.introduction {
        padding: 0px 30px;
    }

}

@media (max-width: 480px) {
    .dimension-container.introduction {
        padding: 0px 0px;
    }

}

.dimension-container.price-quote {
    margin-top: 20px;
    margin-bottom: 40px;
    padding-top: 0;
    overflow: hidden;
}

.dimension-container.price-quote div {
    /*display: inline-block;*/
}

.dimension-container.price-quote .contact-phone {
    float: left;
    font-weight: bold;
}


@media (max-width: 600px) {
    .dimension-container.price-quote .contact-phone {
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.dimension-container.price-quote .contact-phone p {
    display: inline-block;
    font-size: 20px;
    color: #ff0d1b;
    margin: 0px 20px;
    padding-top: 5px;
}

@media (max-width: 840px) {
    .dimension-container.price-quote .contact-phone p {
        display: none;
    }

}


.dimension-container.price-quote .contact-phone img {
    width: 55px;
    height: auto;
}

.dimension-container.price-quote .contact-phone a {
    color: #294352;
    margin-left: 20px !important;
}

.dimension-container.price-quote .get-quote {
    float: right;
}


@media (max-width: 1023px) {
    .container-dimension .home-price-guide .price-guide-wrapper .price-guide-img img {
        top: 20px;
    }

}

@media (max-width: 480px) {
    .home-price-guide {
        margin-top: 90px;
    }
}

@media (max-width: 400px) {
    .get-quote.button-wrapper .btn-primary {
        font-size: 12px;
        line-height: 15px;
        width: 140px;
    }

    .price-quote .contact-phone a {
        display: none;
    }

}

/* mega menu */
.usc-content .usc-content-block .usc-container .usc-container-type .usc-container-row .usc-container-name {
    font-size: 11px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.usc-content .usc-content-block .usc-container {
    padding-bottom: 5px !important;
}

.usc-content .usc-content-block .usc-container .usc-container-type .usc-container-row {
    height: 22px !important;
}

/* FAQ page */
.faq-detail .accordion .slide-text .read-more p {
    margin-top: 20px;
}

.faq-detail .faq-subtitle {
    padding-left: 0px;
}

.faq-detail .accordion > li > a {
    padding-left: 0px;
}

.faq-detail .accordion .slide-text {
    padding-left: 0px;
}


.product-info-container .product-info-left .alternative-names {
    display: block;
    margin-top: 20px;
    text-align: left;
}

/* location page footer */
.footer .states-inner.location {
    justify-content: center !important;
}

.removal-banner .home-lead .home-lead-left .mod-heading-des, .mod-banner .home-lead .home-lead-left .mod-heading-des {
    min-height: 200px;
}

/* homepage */

@media (max-width: 479px) {
    .footer .states-wrapper .container .states {
        width: 100%;
        -ms-flex-pack: distribute;
        justify-content: flex-start;
    }
}

.product-info-footer .btn-primary {
    font-size: 15px !important;
}

.product-info-footer {
    justify-content: center;
}

.new-product-info-container .product-info-left .product-image-holder .slick-slide {
    margin: 0 0px;
}

.home-container .product-panel {
    top: -1px;
}

/* product panel on homepage */
@media (max-width: 1024px) {
    .new-product-info-container .product-info-left {
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important;
        width: 100% !important;
        margin-top: 30px;
    }

    .new-product-info-container .product-info-right {
        -ms-flex: 0 0 100%;
        flex: 0 0 100% !important;
        width: 100% !important;
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%);
    }

    .new-product-info-container.reversed .product-info-right {
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%);
    }

    .new-product-info-container {
        margin: 20px 0 0 0 !important;
    }

    .new-product-info-container .product-info-left .product-images-panel h4,
    .new-product-info-container .product-info-left .product-images-panel h5 {
        color: #9b9b9b;
    }

    .new-product-info-container .product-info-left .product-image {
        margin-bottom: 0px;
    }

    .new-product-info-container .product-info-left .product-images-panel {
        -webkit-box-shadow: 0 0 40px 0 rgb(0 0 0 / 20%), 0 0 1px 0 rgb(0 0 0 / 10%);
        box-shadow: 0 0 40px 0 rgb(0 0 0 / 20%), 0 0 1px 0 rgb(0 0 0 / 10%);
        background-color: #fff;
    }

    .new-product-info-container .product-info-left .product-images-panel .product-image-thumbs-alt {
        background-color: #ff1520 !important;
    }

    .product-info-heading-left {
        padding: 35px 35px 15px 35px;
    }

    .new-product-info-container .product-info-left .product-image-thumbs-alt .product-image-thumbs-alt-wrapper {
        padding: 14px 20px 14px 20px;
    }

}

.new-product-info-container .product-info-left .product-images-panel .product-image-thumbs-alt {
    background-color: #ff1520;
}

@media (max-width: 1024px) {
    .new-product-info-container .product-info-left .product-image-thumbs-alt .product-image-thumbs-alt-wrapper {
        background-color: #ff1520;
    }
}

/* google search result page */
.container-google-search-result {
    max-width: 1000px !important;
    margin-top: 30px !important;
}

.gsc-wrapper .gs-title {
    font-weight: 700 !important;
    font-size: 20px !important;
}

.gsc-control-cse .gsc-table-result {
    font-size: 16px !important;
}

.gsc-webResult .gsc-url-top {
    font-size: 16px !important;
}

@media (min-width: 769px) {
    .gsc-wrapper .gs-image {
        max-width: 200px !important;
        max-height: 115px !important;
    }

    .gsc-table-cell-snippet-close {
        padding-left: 215px !important;
        margin-top: 10px;
    }
}

.gsc-wrapper .gsc-adBlock {
    display: none !important;
}

#select-location-list #suburb-list {
    max-height: 300px;
    overflow-y: scroll;
}

header .header-nav-search-wrapper form .header-search-dropdown .location-wrapper {
    padding: 10px 0px 5px 5px;
}

@media (min-width: 1200px) {
    .product-form .product-info-container .product-info-left .product-info.bg-white > p.hire {
        min-height: 200px;
    }
}

.new-home-leader-bottom {
    min-height: 200px !important;
}

.quote-form-contact-message {
    margin-left: 0px !important;
    margin-top: 10px !important;
}

.home-container .product-panel .product-available .available-group ul li {
    width: 20% !important;
    -ms-flex: 0 0 20% !important;
    flex: 0 0 20% !important;
}

/* Delivery page */
.hero-section.delivery .hero-lead-inner {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 400px) {
    .hero-section.delivery .hero-lead-inner,
    .hero-section.delivery::after {
        height: 280px;
    }
}

@media (min-width: 401px) and (max-width: 750px) {
    .hero-section.delivery .hero-lead-inner,
    .hero-section.delivery::after {
        height: 400px;
    }
}

@media (max-width: 750px) {
    .hero-section.delivery .hero-lead-inner {
        padding-bottom: 0px;
    }
}

.location-two-col.delivery .location-two-col-item {
    justify-content: flex-start;
}


.delivery .location-cta-row .location-cta-inner p {
    justify-content: flex-start;
}

section.delivery .location-two-col-item {
    margin-bottom: 20px;
}

section.delivery-costs .delivery-text {
    background-image: url("/images/noun_quotes.svg");
    background-repeat: no-repeat;
    background-position: left -20px top -30px;
    padding-top: 40px;
}

@media (min-width: 769px) {
    .location-two-col-inner.reverse {
        flex-direction: row-reverse;
    }
}

@media (min-width: 768px) {
    section.delivery-methods .content {
        width: 60%;
        margin: 0 auto;
        padding: 0px;
    }
}

section.delivery-methods .home-container-section {
    margin-top: 0px;
}

section.delivery-methods .home-container-section .product-form {
    padding-top: 10px;
}

section.delivery-methods .home-container-section .product-form .new-product-info-container {
    margin-bottom: 0px;
}

.product-form .product-panel .product-ticks.delivery p {
    padding: 0px;
    margin: 0px;
}

.product-form .product-panel .product-ticks.delivery .label {
    margin-left: 5px;
    margin-bottom: 0px;
    line-height: 1.0;
}

.delivery-ready .delivery-carousel-tab-heading {
    justify-content: space-between;
    padding: 0px 50px;
}

.delivery-ready .delivery-carousel-tab-heading li a {
    font-size: 17px;
    color: #294352;
    text-decoration: none;
    font-weight: bold;
}

.delivery-ready .delivery-carousel-tab-heading li a.active {
    color: #ff1520;
    border-bottom: 2px solid #ff1520;
}

.delivery-ready .delivery-carousel-item-inner-tab .delivery-carousel-tab-heading {
    list-style: none;
    padding: 0px 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.delivery-ready .delivery-carousel-item-inner-tab .delivery-carousel-tab-content {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #fff;
    margin-bottom: 50px;
}

.delivery-ready .delivery-carousel-tab-content li {
    display: none;
}

.delivery-ready .delivery-carousel-tab-content li.active {
    display: flex;
    background-color: #294352;
    padding: 70px;
    flex-wrap: wrap;
    flex-direction: row;
    height: 100%;
    align-items: center;
}

.delivery-ready .delivery-carousel-tab-content li.active .carousel-tab-left {
    flex-basis: 60%;
    padding-right: 50px;
}

.delivery-ready .delivery-carousel-tab-content li.active .carousel-tab-right {
    flex-basis: 40%;
}

@media (max-width: 768px) {
    .delivery-ready .delivery-carousel-tab-content li.active {
        padding: 20px;
    }

    .delivery-ready .delivery-carousel-tab-content li.active .carousel-tab-left {
        flex-basis: 100%;
    }

    .delivery-ready .delivery-carousel-tab-content li.active .carousel-tab-right {
        flex-basis: 100%;
    }
}

.clearance-levels-summary .clearance-levels-summary-table {
    margin: 0 auto;
    padding: 0px;
    margin: 0px;
    min-width: 768px;
    width: 100%;
}

.clearance-levels-summary .clearance-levels-summary-table th,
.clearance-levels-summary .clearance-levels-summary-table td {
    width: 25%;
    text-align: center;
    padding: 10px 0px;
}

.clearance-levels-summary .clearance-levels-summary-table td {
    border-bottom: 1px solid #294352;
}

.clearance-levels-summary .clearance-levels-summary-table .bg-white {
    background-color: #ffffff;
    border: 1px solid #294352;
    color: #294352 !important;
}

.clearance-levels-summary .clearance-levels-summary-table .bg-dark {
    background-color: #294352;
    border: 0px solid #294352;
    color: #ffffff !important;
}

.clearance-levels-summary .note {
    margin-top: 10px;
}

.container-placement .location-two-col-item,
.container-racking .location-two-col-item,
.doors-direction .location-two-col-item {
    justify-content: flex-start !important;
}

.container-placement-card .flex {
    justify-content: space-between;
}

.container-placement-card .flex .card {
    flex-basis: 30%;
    text-align: center;
}

.container-placement-card .flex .card .content {
    padding: 0px !important;
    text-align: center;
}

.container-placement-card .flex .card .content p {
    padding: 0px !important;
    text-align: center;
}

.container-placement-card .flex .card .content strong {
    display: block;
    padding: 20px 50px 0px 50px;
    font-size: 17px;
}

@media (max-width: 767px) {
    .container-placement-card .flex .card {
        flex-basis: 100%;
    }
}

.container-placement-card .flex .card .title {
    padding: 20px 50px 0px 50px;
    font-size: 17px;
}

.clearance-levels-summary-table td p {
    margin: 0px;
    padding: 0px;
}

#product-list .navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    list-style: none;
    padding: 50px 50px 0px;
}

#product-list .navigation li a {
    font-size: 20px;
    color: #294352;
    font-weight: 800;
    text-decoration: underline;
}

@media (max-width: 768px) {
    #product-list .navigation {
        display: block;
        padding: 10px;
    }

}

.new-icon-section-wrapper {
    justify-content: center;
}

.new-home-leader-bottom-2 p {
    margin: 40px 5% 0;
}

.new-home-leader-bottom-2 .container {
    text-align: center;
    margin-top: 60px;
}


.dimension-image h2 {
    margin-top: 30px;
    font-size: 26px;
    text-align: center;
}

.dimension-image {
    margin-top: 30px;
}

.dimension-image .container {
    text-align: center;
}

.dimension-image .container img {
    max-width: 600px;
}

.sf-display-none {
    position: fixed;
    bottom: 0;
}

.mobile-menu {
    display: none;
    z-index: 999 !important;
}

.hero-lead-inner .hero-lead-left .hero-slider .hero-slider-item {
    display: none;
}

.hero-lead-inner .hero-lead-left .hero-slider .hero-slider-item:first-child {
    display: block;
}

.hero-lead-inner .hero-lead-left .hero-slider.slick-initialized .hero-slider-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;

    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
}

.get-quotes-form.inner-form .price-guide-message h3 {
    color: black;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
}

.get-quotes-form.inner-form .price-guide-message p {
    font-size: 12px;
}

.get-quotes-form.inner-form .form-price-guide {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.get-quotes-form.inner-form .price-guide-image {
    padding: 0 40px;
    flex: 0 0 40%;
}

@media (max-width: 1220px) {
    .hero-section .hero-lead-inner .hero-lead-left .hero-content-wrap.hero-text h1 {
        padding-right: 10px;
    }

    .dimension-image .container img {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .home-price-guide .price-guide-wrapper .price-guide-form-section h2 {
        font-size: 40px;
    }
}

@media (max-width: 650px) {
    .hero-section .hero-lead-inner .hero-lead-left .hero-content-wrap.hero-text {
        top: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }

    .get-quotes-form.inner-form .price-guide-image {
        display: none;
    }
}

@media (max-width: 480px) {
    .home-price-guide.shelter-price-guide {
        margin-top: 0px;
    }
}

/*
 * https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_modal
 */
/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    max-width: 700px;
    max-height: 700px;
}

.modal-content.quote-form {
    max-width: 700px;
    max-height: 700px;
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    transform: translate(10px, -20px);
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .close {
        transform: translate(20px, -28px);
    }
}

/* Shelter container mega menu */
.removals-menu .removals-left > ul > li span {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 700;
    color: #000000;
    padding: 10px 0;
    cursor: pointer;
}

.removals-menu .removals-left .accordions-content {
    display: flex;
    flex-direction: column;
}

.removals-menu.shelter .removals-left > ul > li > ul li {
    margin-top: 7px;
    margin-bottom: 7px;
}

.removals-menu .removals-left .accordions-content li[size="6mx5m"] {
    order: 1;
}

.removals-menu .removals-left .accordions-content li[size="8mx6m"] {
    order: 2;
}

.removals-menu .removals-left .accordions-content li[size="10mx6m"] {
    order: 3;
}

.removals-menu .removals-left .accordions-content li[size="12mx6m"] {
    order: 4;
}

.removals-menu .removals-left .accordions-content li[size="14mx6m"] {
    order: 5;
}

.removals-menu .removals-left .accordions-content li[size="17mx7m"] {
    order: 6;
}

.shelter-grid .shelter-wrapper .shelter-block[size="6mx5m"] {
    order: 1;
}

.shelter-grid .shelter-wrapper .shelter-block[size="8mx6m"] {
    order: 2;
}

.shelter-grid .shelter-wrapper .shelter-block[size="10mx6m"] {
    order: 3;
}

.shelter-grid .shelter-wrapper .shelter-block[size="12mx6m"] {
    order: 4;
}

.shelter-grid .shelter-wrapper .shelter-block[size="14mx6m"] {
    order: 5;
}

.shelter-grid .shelter-wrapper .shelter-block[size="17mx7m"] {
    order: 6;
}

.shelter-mobile-menu {
    display: flex !important;
    flex-direction: column;
}

.shelter-mobile-menu li[size="6mx5m"] {
    order: 1;
}

.shelter-mobile-menu li[size="8mx6m"] {
    order: 2;
}

.shelter-mobile-menu li[size="10mx6m"] {
    order: 3;
}

.shelter-mobile-menu li[size="12mx6m"] {
    order: 4;
}

.shelter-mobile-menu li[size="14mx6m"] {
    order: 5;
}

.shelter-mobile-menu li[size="17mx7m"] {
    order: 6;
}

.footer .container-types-wrapper .container .container-type {
    width: 32% !important;
}

.home-price-guide.shelter-price-guide .price-guide-wrapper .price-guide-img {
    width: 60%;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
}

.home-price-guide.shelter-price-guide .price-guide-wrapper .price-guide-form-section {
    width: 40%;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
}

@media (max-width: 600px) {
    .home-price-guide.shelter-price-guide .price-guide-wrapper .price-guide-form-section {
        width: 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }
}

.home-price-guide.shelter-price-guide .price-guide-wrapper .price-guide-img img {
    -webkit-transform: translateX(-5%) translateY(-50%);
    -ms-transform: translateX(-5%) translateY(-50%);
    transform: translateX(-5%) translateY(-50%);
}

/* about us page */
.our__location .our__location__cards {
    flex-wrap: wrap;
}

.our__broochures .our__broochures__cards {
    flex-wrap: wrap;
}

.our__broochures .our__broochures__cards .each__card {
    margin-bottom: 20px;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 33px;
    height: 33px;
    margin: 6px;
    border: 3px solid #ff1520;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ff1520 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ct-panels .form-loading {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    display: none;
}

.ct-panels .form-loading .lds-ring {
    position: absolute;
}

.ct-panel .jcf-disabled {
    opacity: 0.6;
    border-left: 2px solid gray !important;
}

ul.ui-autocomplete {
    border: 1px solid #c5c5c5;
    background: #fff;
    color: #333;
}

ul.ui-autocomplete li {
    margin: 0;
    cursor: pointer;
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}

form .form-row.submit {
    display: flex;
    flex-wrap: wrap;
}

form .form-row.submit .form-loading-submit {
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    position: relative;
    z-index: 9;
    display: none;
}

form .form-row.submit .form-loading-submit .lds-ring {
    position: absolute;
}

.jcf-select-sale-depdrop .jcf-list .jcf-optgroup .jcf-option {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
}

.jcf-select-sale-depdrop .jcf-list .jcf-optgroup-caption, .jcf-select-sale-depdrop .jcf-list .jcf-option {
    padding: 5px 10px;
}

#ct-mega-form.mfp-hide {
    display: block !important;
}

.ct-toggle .form-row.where {
    margin-top: 10px;
}

.ct-toggle .form-row select {
    height: 40px;
    width: 100%;
    border: 1px solid #c4cfd6;
    border-left: 2px solid #ff1520;
    line-height: 40px;
    font-size: 13px;
    color: #666;
}
.ct-toggle #ct-new-sale-form .form-row.what select {
    height: 34px;
    width: 100%;
    border: 1px solid #c4cfd6;
    border-left: 2px solid #ff1520;
    line-height: 34px;
    font-size: 13px;
    color: #666;
    margin-right: 5px;
}
@media (min-width: 768px) {
    .ct-toggle .form-row select {
        letter-spacing: -0.9px;
        padding: 0 25px 0 10px;
    }
}
.sidebar .sidebar-form-group .sidebar-from-wrap .form-row.submit input{
    width: auto;
}

.section-container .video-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.section-container .video-wrapper .play-btn-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-25px, -35px);
}

.leading-section {
    margin-top: 40px;
}

.leading-section p {
    text-align: left;
    margin-top: 15px;
}

.product-container.iso-tank .learn-more {
    display: none;
}

.product-container.iso-tank .sales-info {
    display: none !important;
}