/* global */
.display-block {
    display: block !important;
}

.ct_mt_noraml {
    margin-top: 50px !important;
}

.ct-text-bold {
    font-weight: 800 !important;
}

.ct-text-red {
    color: #ff1520 !important;
}

.ct-text-left {
    text-align: left !important;
}

.ct-text-shadow {
    text-shadow: 0px 0px 8px rgba(0,0,0,0.9);
}

.ct-colour-dark {
    color: #294352!important;
}

.ct-colour-blue {
    color: #0006ca !important;
}

.rounded-corner {
    border-radius: 10px !important;
}

.rounded-corner-small {
    border-radius: 5px !important;
}

.background-white {
    background-color: #ffffff !important;
}

.background-grey {
    background-color: #f0f4f7 !important;
}

.background-dark {
    background-color: #294352!important;
}

.container-fullwidth {
    max-width: 100%;
    width: 100%;
}

a.phone_number {
    white-space: nowrap;
}

.btn-primary.btn-arrow {
    min-width: 190px;
}

.home-purpose-section-footer-content a {
    white-space: nowrap;
}

header .secondary-header .secondary-header-inner .secondary-header-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    width: 40%;
}

@media (max-width: 1120px) {
    header .secondary-header .secondary-header-inner .secondary-header-left {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 35%;
        flex: 0 0 35%;
        width: 35%;
    }
}


header .secondary-header .secondary-header-inner .secondary-header-left .small-screen {
    display: none;
}

header .header-main .header-main-inner .header-main-right .header-main-content div .small-screen a {
    color: #ff1520;
}

header .secondary-header .secondary-header-inner .secondary-header-left .large-screen-hide {
    display: none;
}

@media (max-width: 1120px) {
    header .secondary-header .secondary-header-inner .secondary-header-left .large-screen {
        display: none;
    }

    header .secondary-header .secondary-header-inner .secondary-header-left .large-screen-hide {
        display: block;
    }

    header .secondary-header .secondary-header-inner .secondary-header-left .small-screen {
        display: block;
    }
}

header .header-main-right .header-main-content p.small-screen {
    display: none !important;
}

@media (max-width: 1120px) {
    header .header-main-right .header-main-content p.large-screen {
        display: none !important;
    }

    header .header-main-right .header-main-content p.small-screen {
        display: inline-block !important;
    }

}


header .header-main-right .header-main-content.desktop {
    display: block !important;
}

header .header-main-right .header-main-content.mobile {
    display: none !important;
}

@media (max-width: 899px) {
    header .header-main-right .header-main-content p.small-screen {
        margin-left: 10px !important;
    }

    header .header-main .header-main-inner .header-main-right .header-main-content div {
        display: flex;
        flex-direction: row-reverse;
    }

    header .header-main .header-main-inner .header-main-right .header-main-content.mobile div {
        display: block !important;
        padding: 0px 0px 0px 0px;
    }

    header .header-main-right .header-main-content.desktop {
        display: none !important;
    }

    header .header-main-right .header-main-content.mobile {
        display: block !important;
    }

    header .header-main-right .btn-primary {
        width: 161px;
    }

    header .header-main-right .header-main-content.mobile .header,
    header .header-main-right .header-main-content.mobile .phone-number {
        display: block !important;
    }

    header .header-main-right .header-main-content.mobile .header {
        font-size: 12px !important;
    }

    header .header-main-right .header-main-content.mobile .phone-number {
        font-size: 13px !important;
        margin: 0px !important;
        padding: 0px 15px 5px 15px;
    }

    header .header-main-right .header-main-content.mobile .call-button {
        width: 100% !important;
    }

    header .header-main-right .header-main-content.mobile .call-button .btn-primary {
        background-color: transparent;
        border: 1px solid #c4cfd6;
    }

    header .header-main .header-main-inner .header-main-right {
        justify-content: space-evenly;
        padding-left: 0;
        padding-right: 0;
        margin-right: 0px;
    }

    header .header-main .header-main-inner .mobile-menu-trigger {
        margin-right: 5%;
    }

}

@media (max-width: 899px) {
    header .header-main-right .btn-primary {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (max-width: 421px) {
    header .header-main-right .button-wrapper {
        -ms-flex: 0 0 131px !important;
        flex: 0 0 131px !important;
        width: 131px !important;
    }

    header .header-main-right .btn-primary {
        width: 125px;
        font-size: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }



    header .header-main-right .btn-primary  .icon-arrow {
        font-size: 11px;
    }
}

header .secondary-header .secondary-header-inner .secondary-header-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    width: 60%;
}

header .secondary-header .secondary-header-inner .secondary-header-right .secondary-header-nav li {
    padding: 12px 10px;
}

@media (max-width: 1120px) {
    header .secondary-header .secondary-header-inner .secondary-header-right {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 65%;
        flex: 0 0 65%;
        width: 65%;
    }
}

header .header-main .header-main-inner .logo-wrapper img {
    width: 300px;
}

.header-nav-wrapper .menu a.small-screen {
    display: none;
}

@media (max-width: 1199px) {
    .header-nav-wrapper .menu a.large-screen {
        display: none;
    }

    .header-nav-wrapper .menu a.small-screen {
        display: inline-block;
    }
}

@media (max-width: 899px) {
    header .header-main .header-main-inner .logo-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    header.sticky .header-main .header-main-inner .logo-wrapper {
        /*display: none;*/
    }

    header .header-main .header-main-inner .logo-wrapper img {
        width: 200px;
    }

    #top .header-main .container,
    #mobile-topbar-sticky .header-main .container {
        padding: 0px;
    }

    header .header-main .header-main-inner .header-main-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-right: 20px;
    }

    header .header-main .header-main-inner .header-main-right .button-wrapper {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 161px;
        flex: 0 0 161px;
        width: 161px;
        text-align: right;
    }

    header .header-main .header-main-inner .header-main-right .header-main-content {
        padding-right: 0px;
    }

}

@media (max-width: 400px) {
    header .header-main .header-main-inner .header-main-wrapper .header-main-right .small-screen {
        font-size: 13px;
        transform: translateY(3px);
    }

    header .header-main .header-main-inner .header-main-wrapper .header-main-right .btn-get-quote-popup {
        font-size: 10px;
    }

    header .header-main .header-main-inner .header-main-wrapper .header-main-right .icon-arrow {
        font-size: 10px;
    }

    header .header-main-right .header-main-content.mobile .header {
        font-size: 11px !important;
    }

    header .header-main-right .header-main-content.mobile .phone-number {
        font-size: 11px !important;
    }
}

.hero-section .hero-lead-inner .hero-lead-left .hero-slider .hero-slider-item::after {
    background-color: transparent;
}

@media (min-width: 601px) {
    .hero-section .hero-lead-inner .hero-lead-left .hero-content-wrap h1 {
        text-shadow: 0px 0px 8px rgba(0,0,0,0.9);
    }

    .hero-section .hero-lead-inner .hero-lead-left .hero-content-wrap .alternative-names {
        text-shadow: 0px 0px 8px rgba(0,0,0,0.9);
    }
}

.hero-content-wrap.simple-title {
    top: 62%!important;
}

@media (min-width: 610px) {
    .hero-section .hero-lead-inner .hero-lead-left .hero-content-wrap {
        top: auto !important;
        transform: translate(-50%, -55px) !important;
    }
    .hero-section .hero-lead-inner .hero-lead-left .hero-content-wrap.homepage-content {
        top: 65% !important;
        transform: translate(-50%, -50%) !important;
    }

    .hero-section .hero-lead-inner .hero-lead-left .hero-content-wrap.simple-title {
        transform: translate(-50%, 0px) !important;
        bottom: 42px;
    }
}

.hero-section .hero-lead-inner .home-lead-right {
    top: 6%;
}

@media (max-width: 1400px) {
    .hero-section .hero-lead-inner .home-lead-right {
        top: 2%;
    }
}

.hero-content-wrap.extra-content {
    bottom: 0px!important;
}

header .header-nav-wrapper nav .menu .top-menu-search {
    margin-right: -55px;
    z-index: 99;
}

header .header-nav-wrapper nav .menu .has-child.top-burger-menu {
    padding-left: 60px;
}

header .header-nav-wrapper nav .menu .has-child.top-burger-menu {
    background-color: transparent !important;
}

/* sticky header */
header#top.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: #ffffff;
    z-index: 999;
}

.breadcrumb-wrapper.sticky {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;
    width: 100%;
    background-color: #ffffff;
}

@media (min-width: 1200px) {
    header#top.sticky {
        /*transform: translateY(-45px);*/
    }
    .breadcrumb-wrapper.sticky {
        transform: translateY(226px);
    }
}

@media (max-width: 1199px) {
    header#top.sticky {
        /*top:80px;*/
    }
}

@media (min-width: 900px) and (max-width: 1199px) {
    .breadcrumb-wrapper.sticky {
        transform: translateY(226px);
    }
}

@media (max-width: 899px) {
    .breadcrumb-wrapper.sticky {
        transform: translateY(60px);
    }
}

@media (max-width: 899px) {
    header#top.sticky.hide {
        animation-duration: 1s;
        animation-name: topMenuSlideUp;
        animation-fill-mode: forwards;
    }

    header#top.sticky.hide-down {
        animation-duration: 1s;
        animation-name: topMenuSlideDownHalf;
        animation-fill-mode: forwards;
    }

    header#top.sticky.show {
        animation-duration: 1s;
        animation-name: topMenuSlideDown;
        animation-fill-mode: forwards;
    }


    @keyframes topMenuSlideUp {
        from { transform: translateY(0px); }
        to { transform: translateY(-80px); }
    }

    @keyframes topMenuSlideDown {
        from { transform: translateY(-80px); }
        to { transform: translateY(0px); }
    }

    @keyframes topMenuSlideDownHalf {
        from { transform: translateY(-200px); }
        to { transform: translateY(-80px); }
    }

    .mm-wrapper_opening .mm-menu_position-right.mm-menu_opened~.mm-slideout header#top {
        animation-duration: 1s;
        animation-name: topMenuSlideUp;
        animation-fill-mode: forwards;
    }

    .mm-wrapper:not(.mm-wrapper_opening) .mm-menu_position-right~.mm-slideout header#top:not(.sticky):not(.initial) {
        animation-duration: 1s;
        animation-name: topMenuSlideDown;
        animation-fill-mode: forwards;
    }

    .mobile-menu-trigger.opening .burger-item span {
        display: block;
        height: 2px;
        width: 20px;
        background-color: #fff;
    }

    .mobile-menu-trigger.opening .burger-item span::before {
        content: "";
        position: absolute;
        height: 2px;
        width: 20px;
        left: 0;
        top: 2px;
        background-color: #294352;
        transform: rotate(45deg);
        transform-origin: left center;
        -webkit-transition: all ease .3s;
        -o-transition: all ease .3s;
        transition: all ease .3s;
    }

    .mobile-menu-trigger.opening .burger-item span::after {
        content: "";
        position: absolute;
        height: 2px;
        width: 20px;
        right: 0;
        bottom: 2px;
        background-color: #294352;
        transform: rotate(-45deg);
        transform-origin: left center;
        -webkit-transition: all ease .3s;
        -o-transition: all ease .3s;
        transition: all ease .3s;
    }


}



.ct-toggle .ct-tabs .ct-tab span {
    font-size: 17px;
}

@media (max-width: 479px) {
    .ct-toggle .ct-tabs .ct-tab span {
        font-size: 11px !important;
    }
}

/* mobile menu */
/*
#mobile-menu.hide {
    animation-name: mobile-menu-slide-out;
    animation-duration: 0.5s;
    -webkit-animation-name: mobile-menu-slide-out;
    -webkit-animation-duration: 0.5s;;
    -moz-animation-name: mobile-menu-slide-out;
    -moz-animation-duration: 0.5s;
}

#mobile-menu.show {
    animation-name: mobile-menu-slide-in;
    animation-duration: 0.5s;
    -webkit-animation-name: mobile-menu-slide-in;
    -webkit-animation-duration: 0.5s;
    -moz-animation-name: mobile-menu-slide-in;
    -moz-animation-duration: 0.5s;
}
*/

/*
#mobile-menu:not(.mm-menu_opened) {
    animation-name: mobile-menu-slide-out;
    animation-duration: 0.5s;
    -webkit-animation-name: mobile-menu-slide-out;
    -webkit-animation-duration: 0.5s;;
    -moz-animation-name: mobile-menu-slide-out;
    -moz-animation-duration: 0.5s;
}
*/

/*
#mobile-menu.mm-menu_opened {
    animation-name: mobile-menu-slide-in;
    animation-duration: 0.5s;
    -webkit-animation-name: mobile-menu-slide-in;
    -webkit-animation-duration: 0.5s;
    -moz-animation-name: mobile-menu-slide-in;
    -moz-animation-duration: 0.5s;
}
*/

@keyframes mobile-menu-slide-in {
    from {transform: translateX(1000px);}
    to {transform: translateX(0px);}
}
@-webkit-keyframes mobile-menu-slide-in {
    from {-webkit-transform: translateX(1000px);}
    to {-webkit-transform: translateX(0px);}
}
@-moz-keyframes mobile-menu-slide-in {
    from {-moz-transform: translateX(1000px);}
    to {-moz-transform: translateX(0px);}
}

@keyframes mobile-menu-slide-out {
    from {transform: translateX(0px);}
    to {transform: translateX(1000px);}
}
@-webkit-keyframes mobile-menu-slide-out {
    from {-webkit-transform: translateX(0px);}
    to {-webkit-transform: translateX(1000px);}
}
@-moz-keyframes mobile-menu-slide-out {
    from {-moz-transform: translateX(0px);}
    to {-moz-transform: translateX(1000px);}
}

.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(0,0,0) !important;
    transform: translate3d(0vw,0,0) !important;
}

#mobile-topbar-sticky {
    display: none;
    position: fixed;
    top: 0px;
    left:200%;
    z-index: 9999;
    background-color: #ffffff;
    width:100%;
    height:64px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.mm-wrapper.mm-wrapper_opened header#top {
    /*display: none;*/
}

.mm-wrapper.mm-wrapper_opened #mobile-topbar-sticky {
    display: block;
}

.mm-wrapper.mm-wrapper_opened #mobile-menu {
    top: 60px;
}


#mobile-topbar-sticky .mobile-menu-trigger .burger-item span {
    height: 0px;
}

#mobile-topbar-sticky .mobile-menu-trigger .burger-item span::before {
    content: "";
    height: 2px;
    width: 20px;
    transform: rotate(45deg);
    transform-origin: top left;
}

#mobile-topbar-sticky .mobile-menu-trigger .burger-item span::after {
    content: "";
    height: 2px;
    width: 20px;
    transform: rotate(-45deg);
    transform-origin: bottom left;
}

.mm-wrapper_blocking .mm-wrapper__blocker {
    display: none !important;
}

#mobile-menu .mm-btn_close {
    transform: translateX(200%);
}

#mobile-menu .highlight-menu-item {
    background-color: #ff1520;
}

#mobile-menu .highlight-menu-item a {
    color: #ffffff;
}

#mobile-menu .highlight-menu-item .mm-btn:after,
#mobile-menu .highlight-menu-item .mm-btn:before {
    border-right: 2px solid #ff1520;
    border-bottom: 2px solid #ff1520;
    border-top: 2px solid #ffffff !important;
    border-left: 2px solid #ffffff !important;
}


.mm-menu_offcanvas.mm-menu_opened {
    display: flex !important;
    transform: translateX(0%);
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transition: 0.5s ease-in-out;
    top: 60px;
}

.mm-menu_offcanvas:not(.mm-menu_opened) {
    display: flex !important;
    transform: translateX(200%);
    -moz-transform: translateX(200%);
    -webkit-transform: translateX(200%);
    transition: 0.5s ease-in-out;
    top: 60px;
}

.mm-navbars_top.hide {
    display: none;
}

@media (max-width: 900px) {
    .mfp-container {
        top: 70px;
    }
}

/* mega form */
.jcf-list .jcf-option {
    color: #000000;
}

.jcf-list-box .jcf-selected, .jcf-select-drop .jcf-hover {
    background: #294352;
    color: #fff !important;
}

/* footer */
.footer {
     -webkit-box-shadow: none;
     box-shadow: none;
}

.footer .container .footer-right {
    display: flex;
}

.footer .container .footer-right .footer-logos .csta-logo {
    max-width: 160px;
    margin-right: 50px;
}

/* thank you page */
.thank-you-wrapper .content {
    text-align: left;
}

.thank-you-wrapper .content img,
.thank-you-wrapper .content .btn-primary {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.thank-you-wrapper .content ul li {
    list-style: none;
}

.thank-you-wrapper .content ul li i {
    color: #ff1520;
    font-weight: 900;
    margin-right: 10px;
    margin-top: 3px;
}

.home-purpose-section.modifications .purpose-section {
    border-bottom: 0px solid #d6d6d6;
}

.home-purpose-section.modifications .circle-nav-wrapper .circle-nav .circle-nav-item-outter .circle-nav-item.active {
    /*border: 4px solid #ff1520;*/
    border: 0px solid #ff1520;
    -webkit-transform: scale(1.0);
    -ms-transform: scale(1.0);
    transform: scale(1.0);
}

.home-purpose-section.modifications .circle-nav-wrapper .circle-nav .circle-nav-item-outter .circle-nav-item {
    border-radius: 0;
    width: 200px;
    /*height: 140px;*/
    height: 0px;
}

@media (max-width: 550px) {
    .home-purpose-section.modifications .circle-nav-wrapper .circle-nav .circle-nav-item-outter .circle-nav-item {
        width: 120px;
        height: 84px;
        display: none;
    }
}

.home-purpose-section.modifications .circle-nav-wrapper .circle-nav .circle-nav-item-outter .circle-nav-item:before {
    border-radius: 0;
    width: 140px;
    height: 140px;
}

.home-purpose-section.modifications .circle-nav-wrapper .circle-nav .circle-nav-item-outter .circle-nav-item:before {
    opacity: 0;
}

.home-purpose-section.modifications .circle-nav-wrapper .circle-nav .circle-nav-item-outter .circle-nav-item.active:before {
    opacity: 0;
}

.home-purpose-section.modifications .circle-nav-wrapper .circle-nav .circle-nav-item-outter .circle-nav-item + p {
    position: relative;
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 800;
    padding: 0;
    z-index: 1;
    color: #294352;
    transform: translateY(-3px);
}

.home-purpose-section.modifications .circle-nav-wrapper .circle-nav .circle-nav-item-outter .circle-nav-item.active + p {
    color: #ff1520;
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-heading li a.active {
    border-top: 2px solid #ff1520;
    border-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 20px;
}

@media (max-width: 767px) {
    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-heading li a.active {
        border-top: 0px;
        border-bottom: 2px solid #ff1520;
        padding-top: 5px;
        padding-bottom: 20px;
    }
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content {
    padding-top: 10px;
    border-top: 1px solid rgba(255,255,255,0.2);
    padding-top: 40px;
}

@media (max-width: 1023px) {

    .home-purpose-section.modifications .circle-nav-wrapper .circle-nav .circle-nav-item-outter {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        width: 0 0 50%;
    }

    /*
    .home-purpose-section.modifications .circle-nav-wrapper .circle-nav .circle-nav-item-outter .circle-nav-item {
        width: 60px;
        height: 60px;
    }
    */

    .home-purpose-section.modifications .circle-nav-wrapper .circle-nav .circle-nav-item-outter .circle-nav-item:before {
        width: 60px;
        height: 60px;
    }
    .home-purpose-section.modifications .circle-nav-wrapper .circle-nav .circle-nav-item-outter .circle-nav-item p {
        font-size: 12px;
        line-height: 20px;
        transform: translateY(170%);
    }

    .home-purpose-section.modifications .purpose-carousel .carousel-item-new-top {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }

}

@media (max-width: 767px) {
    .circle-nav-wrapper .circle-nav .circle-nav-item-outter {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        width: 0 0 25%;
        margin-bottom: 20px;
    }
}

.content .about-us-introduction h2.about-us {
    font-size: 24px !important;
    margin-top: 35px !important;
    margin-bottom: 10px !important;
    line-height: 1.2 !important;
}

.about__hero h1 {
    transform: translateY(180%);
}

.about__form .faq-content .single-faq-wrap {
    border-bottom: 0px solid #E2E2E2;
}

.about__form .sidebar {
    border-bottom: 0px solid #E2E2E2;
}

section.reviews {
    padding: 50px 0px;
    background-color: #f2f2f2;
}

@media (min-width: 600px) and (max-width: 700px) {
    .about__hero h1 {
        transform: translateY(220%);
    }
}

@media (min-width: 701px) and (max-width: 800px) {
    .about__hero h1 {
        transform: translateY(270%);
    }
}

@media (min-width: 801px) and (max-width: 950px) {
    .about__hero h1 {
        transform: translateY(300%);
    }
}

@media (min-width: 951px) and (max-width: 1023px) {
    .about__hero h1 {
        transform: translateY(350%);
    }
}

@media (min-width: 1024px) and (max-width: 1100px) {
    .about__hero h1 {
        transform: translateY(200%);
    }
}

@media (min-width: 1101px) and (max-width: 1300px) {
    .about__hero h1 {
        transform: translateY(250%);
    }
}

@media (min-width: 1301px) and (max-width: 1500px) {
    .about__hero h1 {
        transform: translateY(300%);
    }
}

@media (min-width: 1501px) and (max-width: 1700px) {
    .about__hero h1 {
        transform: translateY(350%);
    }
}

@media (min-width: 1701px) and (max-width: 1900px) {
    .about__hero h1 {
        transform: translateY(400%);
    }
}

@media (min-width: 1901px) and (max-width: 2100px) {
    .about__hero h1 {
        transform: translateY(450%);
    }
}

@media (min-width: 2101px) and (max-width: 2500px) {
    .about__hero h1 {
        transform: translateY(500%);
    }
}

@media (min-width: 2501px) {
    .about__hero h1 {
        transform: translateY(600%);
    }
}

.about-hero {
    position: relative;
    width: 100vw;
    height: 31.25vw;
}

.about-hero.community {
    position: relative;
    width: 100vw;
    height: 55vh;
    max-height: 55vh;
    overflow: hidden;
}

@media only screen and (max-width: 1320px) {
    .about-hero.community {
        height: 31vw;
    }
}

.about-hero .slider-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

.about-hero .slider-wrapper img {
    width: 100%;
}

.about-hero h1 {
    position: absolute;
    left: 50%;
    bottom: 0px;
    color: white;
    font-size: 50px;
    transform: translate(-50%, 60%);
}

.about-hero.community h1 {
    bottom: 30px;
}




@media only screen and (max-width: 1320px) {
    .about-hero h1 {
        font-size: 30px;
    }
}

@media only screen and (max-width: 790px) {
    .about-hero h1 {
        font-size: 24px;
    }
}

@media only screen and (max-width: 630px) {
    .about-hero h1 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 530px) {
    .about-hero h1 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 430px) {
    .about-hero h1 {
        font-size: 13px;
    }
}

.about-hero .slider-wrapper .slick-arrow {
    display: none !important;
}

.about-hero .slider-wrapper .slick-dots {
    position: absolute;
    right: 20%;
    bottom: -3%;
    z-index: 999;
}

.about-hero.community .slider-wrapper .slick-dots {
    bottom: 7%;
}

@media (max-width: 800px) {
    .about-hero .slider-wrapper .slick-dots {
        right: 1%;
    }

    .about-hero .slider-wrapper .slick-dots li {
        padding: 0 4px;
    }

    .about-hero .slider-wrapper .slick-dots button {
        width: 8px;
        height: 8px;
    }

}

.container .about-us-content table td {
    padding-right: 15px;
}

@media (max-width: 700px) {
    .container .about-us-content table tr {
        display: flex;
        flex-direction: column;
    }
    .container .about-us-content table td {
        padding-right: 0px;
    }
}

/* dimension table */
.mobile-header {
    display: none;
}

.dimension-container {
    margin-top: 10px;
}

.dimension-list-wrapper .mobile-title {
    display: none;
    font-weight: bold;
}

@media (max-width: 1100px) {
    .mobile-header {
        display: none;
    }

    .dimension-list {
        max-width: 490px;
    }

    .dimension-list table {
        min-width: inherit;
    }

    .dimension-list table thead {
        display: none;
    }
    .dimension-list table td {
        display: block;
    }

    .dimension-list-wrapper .mobile-title {
        display: block;
        margin-bottom: 10px;
    }

    .dimension-list table td {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .dimension-list table td:first-child {
        text-align: center !important;
    }

    .dimension-list table tr {
        border: 0px solid #ffffff !important;
    }

    .dimension-list table td {
        border: 1px solid #294352 !important;
    }

    .dimension-list table tr:not(:nth-child(2)) td:first-child {
        margin-top: 30px;
    }

    .container-dimension .dimension-container.dimension-list-wrapper {
        display: flex;
        justify-content: center;
    }

    .dimension-container .dimension-list {
         -webkit-box-shadow: none;
         box-shadow: none;
    }

    .dimension-list table td:not(:last-child) {
        border-bottom: 0px solid #ffffff !important;
    }
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab {
    padding: 50px 50px 1px 50px;
}

@media (max-width: 1024px) {
    .home-purpose-section.modifications .container {
        padding: 0px;
    }

    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab.rounded-corner {
        border-radius: 0px !important;
    }
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-title {
    font-size: 30px;
    font-weight: 800;
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
}


@media (max-width: 767px) {
    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-title {
        font-size: 25px;
    }
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-title svg {
    stroke: #ffffff;
    transform: translateY(5px);
    margin-right: 15px;
}

@media (max-width: 767px) {
    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-title svg {
        display: none;
    }
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-heading {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: 40px;
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-heading li {
    height: 20px;
    margin: 0px 20px;
    padding: 0px;
}

@media(max-width: 767px) {
    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-heading {
        justify-content: center;
    }
    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-heading li {
        /*margin-left: 0px !important;*/
        /*margin-right: 20px !important;*/
        /*padding: 0px;*/
        /*padding-right: 20px;*/
    }
}

@media (max-width: 1150px) {
    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-heading li {
        margin: 0px 10px 10px 10px;
    }
    .carousel-tab-heading-wrapper {
        margin: 0px 0px !important;
    }
}

@media (max-width: 400px) {
    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-heading {
        margin-bottom: 50px;
    }
}

.brochure-wrapper .slick-arrow {
    color: #ff1520;
}
.brochure-wrapper .icon-chevron-left {
    left: 0px;
}

.brochure-wrapper .icon-chevron-right {
    right: 0px;
}

section.delivery-costs .delivery-text {
    padding-top: 0px;
}

@media (min-width: 600px) {
    .hero-section.delivery .container {
        max-width: inherit;
        padding: 0px;
        overflow-y: hidden;
    }

    .hero-section.delivery .hero-lead-inner .hero-lead-left {
        width: 100%;
        height: 28vw;
    }

    .hero-section.delivery::after {
        height: auto;
    }
    .hero-section.delivery .hero-lead-inner .hero-lead-left .hero-slider .icon-chevron-left,
    .hero-section.delivery .hero-lead-inner .hero-lead-left .hero-slider .icon-chevron-right {
        bottom: 16%;
    }

}

@media (max-width: 600px) {
    .hero-section.delivery .hero-lead-inner .hero-lead-left .hero-slider .slick-slide {
        height: auto;
    }
}

@media (min-width: 600px) and (max-width: 750px) {
    .hero-section.delivery .hero-lead-inner, .hero-section.delivery::after {
        height: auto !important;
    }
}

@media (min-width: 401px) and (max-width: 750px) {
    .hero-section.delivery .hero-lead-inner, .hero-section.delivery::after {
        height: 250px;
    }
}

.delivery.delivery-introduction .delivery-introduction-title {
    font-size: 22px;
    font-weight: 800;
}

.delivery.delivery-introduction .delivery-introduction-bottom {
    font-size: 15px;
    font-weight: 800;
    text-align: right;
    margin-bottom: 0px;
    transform: translateY(15px);
}

.delivery.delivery-introduction .delivery-introduction-bottom-wrapper {
    padding-top: 0px;
    padding-bottom: 15px;
}

.delivery.delivery-introduction .location-two-col-item,
.delivery.delivery-introduction .location-two-col-item p {
    margin-bottom: 0px;
}

@media (max-width: 767px) {
    .delivery.delivery-introduction .delivery-introduction-bottom {
        text-align: center;
    }

    section.delivery .location-cta-row .location-cta-inner p {
         justify-content: center;
    }

}

section.delivery-costs .delivery-text {
    background-image: none;
}

@media (max-width: 1024px) {
    .new-product-info-container.delivery .product-info-left .product-image-thumbs-alt .product-image-thumbs-alt-wrapper {
        background-color: #ffffff;
    }

    .new-product-info-container.delivery .slick-slide img {
        margin: 0 auto;
    }
}

.brochure-slick-wrapper {
    margin: 25px 0;
}

.brochure-slick-wrapper .brochure_cards_wrapper {
    flex-wrap: wrap;
}

@media (min-width: 1024px) {
    .brochure-slick-wrapper .brochure_cards_wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}

.brochure-slick-wrapper .brochure_card {
    width: 100%;
    -webkit-box-shadow: 0px 0px 20px 2px #00000029;
    box-shadow: 0px 0px 20px 2px #00000029;
    padding: 50px 23px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 40px;
    min-height: 410px;
}

@media (min-width: 1199px) and (max-width: 1275px) {
    .brochure-slick-wrapper .brochure_card {
        min-height: 432px;
    }

}

@media (max-width: 600px) {
    .brochure-slick-wrapper .brochure_card {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

@media (min-width: 1024px) {
    .brochure-slick-wrapper .brochure_card-wrapper {
        width: 49%;
        margin-bottom: 0px;
        padding: 0px 20px;
    }
}

.brochure-slick-wrapper .brochure_card img {
    width: 237px;
}

.brochure-slick-wrapper .brochure_card .card_content {
    width: 333px;
    padding-left: 20px;
    background-color: white;
}

.brochure-slick-wrapper .brochure_card .card_content h3 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 600;
}

.brochure-slick-wrapper .brochure_card .card_content p {
    font-size: 16px;
    margin-bottom: 80px;
}

.brochure-slick-wrapper .slick-arrow,
.hire-tab-slider .slick-arrow {
    color: #ff1520;
}

.brochure-slick-wrapper .icon-chevron-left {
    left: 0px;
}

.hire-tab-slider .icon-chevron-left {
    left: -40px;
}

.brochure-slick-wrapper .icon-chevron-right {
    right: 0px;
}

.hire-tab-slider .icon-chevron-right {
    right: -40px;
}

@media (max-width: 768px) {
    .brochure-slick-wrapper .slick-arrow,
    .hire-tab-slider .slick-arrow {
        display: block !important;
    }

    .hire-tab-slider .icon-chevron-left {
        left: -15px;
    }

    .hire-tab-slider .icon-chevron-right {
        right: -15px;
    }

}

.modal.brochure-download-popup .modal-content.quote-form {
    max-width: 1000px;
}

.form-row-half {
    width: 48%;
}

.form-row-half.left {
    float: left;
}

.form-row-half.right {
    float: right;
}

.form-row.submit .btn-primary {
    margin: 0 auto;
}

.image-slider-wrapper .image-thumbs-wrapper {
    display: flex;
}

.image-slider-wrapper .image-thumbs-wrapper-inner {
    width: 25%;
}

.image-slider-wrapper .image-thumbs-wrapper-inner img {
    padding-right: 0px !important;
}

.image-slider-wrapper .slick-arrow {
    top: 80%;
}

.image-slider-wrapper .icon-chevron-left {
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    width: 40px;
    height: 40px;
    color: #fc2a34;
    text-align: center;
    line-height: 40px;
    -webkit-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
}

.image-slider-wrapper .icon-chevron-right {
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    width: 40px;
    height: 40px;
    color: #fc2a34;
    text-align: center;
    line-height: 40px;
    -webkit-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
}

.mod-slider-wrapper .image-slider-wrapper .image-thumbs {
    padding-right: 50px;
}
@media (max-width: 1024px) {
    .mod-slider-wrapper .image-slider-wrapper .image-thumbs {
        padding-right: 0px;
    }
}

.call-our-team-wrapper {
    display: flex;
    justify-content: center;
}

.call-our-team {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px;
}

.call-our-team p {
    font-size: 20px;
    color: #294352;
    font-weight: bold;
    margin: 0;
}

.call-our-team.text-white p {
    color: #ffffff;
}

.call-our-team a:not(.btn-primary) {
    color: #ff1520;
    font-weight: bold;
    font-size: 20px;
}

.call-our-team > *:not(:last-child) {
    margin-right: 10px;
}

.price-guide-form .submit {
    display: block !important;
    margin-top: 10px;
    text-align: center;
}

.modal.brochure-download-popup .close {
    transform: translate(19px, -28px);
}

.mod-option .mod-grid .mod-block .mod-block-footer .footer-top {
    text-align: center;
    padding: 20px 20px 0px 20px;
}

.mod-option .mod-grid .mod-block .mod-block-footer .footer-top h4 {
    font-size: 20px;
    line-height: 24px;
}

.mod-option .mod-grid .mod-block .mod-block-footer {
    flex-direction: column;
}

.mod-option .mod-grid .mod-block .mod-block-footer .footer-bottom {
    justify-content: right;
}

.mod-option .mod-grid .mod-block .mod-block-footer .footer-bottom p,
.mod-option .mod-grid .mod-block .mod-block-footer .footer-bottom p a {
    font-size: 18px;
}
.mod-option .mod-product-info-container .product-image-thumbs-wrapper-inner img {
    cursor: pointer;
}

@media (max-width: 1100px) {
    .product-grid-section.section-container .container .section-content-wrapper .product-container .product-content .product-content-left .product-img-main {
         height: auto;
    }
}

.footer .states-wrapper .states .area {
    font-size: 16px;
    font-weight: 700;
    color: #294352;
    margin-bottom: 10px;
}

.footer .states-wrapper .states a {
    color: #294352;
}

.footer .states-wrapper .container {
    padding-top: 50px;
}

.removal-tab .removal-tab-outer .tab-contents .tab-content-wrap .tab-contents-right img {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
}

.single-article img {
    display: block;
}

.product-grid-section.section-container .container .section-content-wrapper .product-container .product-footer .footer-des {
    text-align: left;
}

.product-grid-section .product-container .product-image .slick-list {
    aspect-ratio: 10/6.6;
}

.product-grid-section.section-container .container .section-content-wrapper .product-container .product-content .product-content-left .product-img-main {
    height: auto;
}

.product-grid-section.section-container .container .section-content-wrapper .product-container .product-content .product-content-right .product-content-right-inner .product-description {
    /*height: 210px;*/
    min-height: 210px;
    padding-bottom: 15px;
}

.product-grid-section.section-container .container .section-content-wrapper .product-container .product-content .product-content-right .product-content-right-inner .sales-info {
    padding-bottom: 8px;
    border-top: 0px solid #c4cfd6;
}

@media (min-width: 768px) {
    .product-grid-section.section-container .container .section-content-wrapper .product-container .product-content .product-content-right .product-content-right-inner .sales-info {
        border-top: 1px solid #c4cfd6;
    }
}


.new-product-info-container .product-info-right .desktop-only h3 {
    font-size: 26px;
}

.new-product-info-container .product-info-right .product-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.new-product-info-container .product-info-right .product-panel {
    padding-bottom: 0px;
}

.new-product-info-container .product-info-left .product-image-thumbs-alt .product-image-thumbs-alt-wrapper {
    padding: 14px 0 14px 0px;
}

.home-container.home-page .product-panel .product_description {
    height: 300px;
    overflow-y: auto;
}

.home-container.home-page .product-panel .product-ticks {
    height: 195px;
    overflow-y: auto;
    padding-top: 0px;
    margin-top: 0px;
}

@media (max-width: 1024px) {
    .home-container.home-page .product-panel .product_description {
        height: auto;
    }

    .home-container.home-page .product-panel .product-ticks {
        height: auto;
    }

    .new-product-info-container .product-info-right .product-panel {
        padding-bottom: 35px;
    }

}


@media (min-width: 1025px) {
    .home-container.home-page .product-images-panel .product-image-holder {
        height: 458px;
    }
}

.ct-toggle .ct-tabs {
    justify-content: space-between;
}

.ct-toggle .ct-tabs .ct-tab {
    margin-right: 0px;
    width: 19.9%;
    padding-top: 15px;
}

.ct-toggle .ct-tabs .ct-tab:last-of-type {
    width: 20%;
}

.carousel-tab-heading-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0px 15%;
}

.carousel-tab-heading-wrapper .carousel-nav-arrows {
    width: 20px;
}

@media (max-width: 700px) {
    .carousel-tab-heading-wrapper .carousel-nav-arrows {
        display: none;
    }

    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content {
        border-top: 0px;
        margin-bottom: 0px;
    }

    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-heading li a.active {
        padding-bottom: 5px;
    }
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-left {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-right {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
}

@media (max-width: 900px) {
    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-left {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        width: 100%;
    }

    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-right {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        width: 100%;
    }

    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-left .carousel-tab-content-image {
        margin-right: 0px !important;
        margin-bottom: 10px !important;
    }

}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-left .carousel-tab-content-image img {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-left .carousel-tab-content-image {
    margin-right: 20px;
    margin-bottom: 0px;
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-left .carousel-tab-content-image img {
    margin-top: 0px;
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-right h5 {
    font-size: 25px;
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-right .bottom-wrapper {
    display: flex;
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-right .bottom-wrapper .image-wrapper {
    width: 25%;
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-right .bottom-wrapper .download-link-wrapper {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-right .bottom-wrapper .download-link-wrapper .download-intro {
    margin-bottom: 20px;
}

.home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-right .bottom-wrapper .download-link-wrapper a.btn-primary {
    max-width: 300px;
    background-color: #ffffff;
    color:#ff1520;
}

.home-removal-section .circle-items .circle-item {
    position: relative;
     border-radius: 0;
}

.home-removal-section .circle-items .circle-item .steps-wrapper {
    display: none;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #ff1520;
}

.home-removal-section .circle-items .circle-item .steps-wrapper .steps {
    position: absolute;
    top: calc(30% - 4px);
    left: calc(50% + 1px);
    transform: translateX(-50%);
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;

}

.home-removal-section .circle-items .circle-item img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.home-removal-section .circle-items .circle-item .label {
    position: absolute;
    left: 50%;
    top: 90%;
    transform: translate(-50%, 0%);
    color: #294352;
    font-size: 15px;
    width: 100%;
    padding: 0;
    line-height: 18px;
}

@media (max-width: 1200px) {
    .home-removal-section .circle-items .circle-item .label {
        top: 90%
    }
}

.ebook-block.homepage {
    margin-top: 50px;
}

.ebook-block .ebook-inner .ebook-image-wrapper {
    margin-top: -63px;
}

.ebook-block .ebook-inner .ebook-image-wrapper img {
    transform: translateX(-8%);
}

.ebook-block.location-page {
    margin-top: 80px;
}

.ebook-block.homepage .ebook-inner,
.ebook-block.location-page .ebook-inner,
.ebook-block.removals .ebook-inner {
    min-height: 410px;
}

.ebook-block .ebook-inner .ebook-image-wrapper.homepage,
.ebook-block .ebook-inner .ebook-image-wrapper.location-page,
.ebook-block .ebook-inner .ebook-image-wrapper.removals {
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    width: 35%;
    margin-top: -200px;
}

@media (max-width: 1199px) {
    .ebook-block .ebook-inner .ebook-content-wrapper.homepage,
    .ebook-block .ebook-inner .ebook-content-wrapper.location-page,
    .ebook-block .ebook-inner .ebook-content-wrapper.removals {
        padding-top: 0px;
        margin-top: 0px;
    }

    .ebook-block .ebook-inner .ebook-image-wrapper.homepage,
    .ebook-block .ebook-inner .ebook-image-wrapper.location-page,
    .ebook-block .ebook-inner .ebook-image-wrapper.removals {
        margin-top: -100px;
    }
}

.ebook-block.removals {
    margin-top: 80px !important;
}

.home-removal-section .circle-items .circle-item .steps-wrapper {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}

.ebook-image-wrapper.homepage img {
    /* box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.5); */
}

/* footer location section */

.footer .states-wrapper .container .states {
    width: 25%;
}

@media (min-width: 401px) and (max-width: 600px) {
    .footer .states-wrapper .container .states {
        width: 50%;
    }
}


@media (max-width: 400px) {
    .footer .states-wrapper .container .states {
        width: 100%;
    }
}

.footer .states-wrapper .container .states-inner {
    justify-content: flex-start;
}

.footer .states-wrapper .container .states-inner .states {
    margin: 15px 0px;
}

.footer .states-wrapper .container .states-inner:not(.masonry) .states {
    width: 100%;
}

.footer .states-wrapper .container .states-inner:not(.masonry) .states.location-col-4 {
    width: 25%;
    margin: 0px;
}

@media (min-width: 401px) and (max-width: 600px) {
    .footer .states-wrapper .container .states-inner:not(.masonry) .states.location-col-4 {
        width: 50%;
        margin: 0px;
    }
}

@media (max-width: 400px) {
    .footer .states-wrapper .container .states-inner:not(.masonry) .states.location-col-4 {
        width: 50%;
        margin: 0px;
    }
}


.footer .states-wrapper .container .states-inner:not(.masonry) .states div {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.footer .states-wrapper .container .states-inner:not(.masonry) .states div .area {
    width: 100%;
}

.footer .states-wrapper .container .states-inner:not(.masonry) .states div p {
    width: 25%;
    padding-right: 10px;
}

@media (min-width: 401px) and (max-width: 600px) {
    .footer .states-wrapper .container .states-inner:not(.masonry) .states div p {
        width: 50%;
        padding-right: 10px;
    }
}

@media (max-width: 400px) {
    .footer .states-wrapper .container .states-inner:not(.masonry) .states div p {
        width: 50%;
        padding-right: 10px;
    }
}

.states-wrapper.shipping-location-homepage {
    margin-top: 0px;
}

@media (max-width: 767px) {
    .home-purpose-section .purpose-section .category-section {
        justify-content: center;
    }

    .home-purpose-section .purpose-section .category-section .single-category {
        margin-bottom: 20px;
    }

}

@media (max-width: 600px) {
    .home-purpose-section .purpose-section .category-section {
        justify-content: center;
    }

    .home-purpose-section .purpose-section .category-section .single-category {
        width: 33%;
        padding: 0 10px;
    }

}

@media (max-width: 759px) {
    .home-removal-section .container {
        padding-bottom: 35px;
    }
}

.home-removal-section .circle-items {
    justify-content: space-evenly;
}

@media (min-width: 1200px) {
    .removal-section .removal-links h4 {
        font-size: 20px;
    }

    .home-removal-section .circle-items .circle-item .steps-wrapper .steps {
        top: calc(30% - 3px);
        font-size: 16px;
    }

    .home-removal-section .circle-items .circle-item .label {
        font-size: 16px;
    }

    .ebook-block .ebook-inner {
        padding: 0 50px;
    }

}

.new-product-info-container .product-image-holder img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.circle-nav-wrapper .circle-nav .slick-arrow {
    display: none !important;
}


.circle-nav-wrapper .circle-nav .slick-list {
    width: 100%;
}

/*
.circle-nav-wrapper .circle-nav .slick-list .slick-track {
    display: flex;
    width: 100%;
}*/

header .secondary-header .secondary-header-inner .secondary-header-left > ul > li::after {
    width: 0px;
}

header .secondary-header .secondary-header-inner .secondary-header-left > ul > li:first-child::before {
    width: 0px;
}

header .secondary-header .secondary-header-inner .secondary-header-left > ul > li a {
    font-weight: 500;
}

header .secondary-header .secondary-header-inner .secondary-header-right .secondary-header-nav li a {
    font-weight: 500;
}

.new-product-info-container {
    margin: 80px 0 0 0;
}

.home-price-guide .price-guide-wrapper .price-guide-img.shelter-landing-page img {
    width: auto;
}

.home-price-guide.modification-brochure .price-guide-wrapper .price-guide-img {
    width: 33%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    position: relative;
}

.home-price-guide.modification-brochure .price-guide-wrapper .price-guide-img img {
    top: 55%;
}

.hire-section.section-container .container .section-content-wrapper .section-details-wrapper {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
}

.why-hiring-section .why-container-list .btn-primary {
    margin-top: 20px;
}

.container-footer-slider .footer-slider-wrapper img {
    width: 100%;
    padding: 0px 20px;
}

@media (max-width: 767px) {
    .container-footer-slider .footer-slider-wrapper img {
        padding: 0px 10px !important;
    }

    .container-footer-slider .container-fullwidth .slider-text-wrapper {
        margin: 0px 10px !important;
    }
}

.container-footer-slider .container-fullwidth .slider-image-wrapper {
    position: relative;
    margin: 0 5px;
}

.hire-section.section-container .section-heading-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.hire-section.section-container .section-heading-wrapper .section-left-placeholder-wrapper {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 23%;
    flex: 0 0 23%;
    overflow: hidden;
}

.hire-section.section-container .section-heading-wrapper .section-right-wrapper {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
}

.hire-section.section-container .section-heading-wrapper .section-right-wrapper h2 {
    float: left;
}

.container-footer-slider .container-fullwidth .slider-text-wrapper {
    position: absolute;
    bottom: 5%;
    left: 5%;
    color: #ffffff;
    font-size: 35px;
    font-weight: 600;
}

@media (max-width: 1200px) {
    .container-footer-slider .footer-slider-wrapper img {
        padding: 0 !important;
    }
}

@media (min-width: 601px) and (max-width: 1200px) {
    .container-footer-slider .container-fullwidth .slider-text-wrapper {
        font-size: 25px;
    }
}

@media (max-width: 600px) {
    .container-footer-slider .container-fullwidth .slider-text-wrapper {
        left: 2%;
        font-size: 14px;
    }
}


.container-footer-slider .icon-chevron-left,
.container-footer-slider .icon-chevron-right {
    top: 50%;
    bottom: unset;
    transform: translateY(-50%) !important;
    background-color: rgba(255, 255, 255, 0.8);
    width: 64px;
    height: 64px;
    border: 1px solid rgba(56, 76, 88, 0.4);
    color: #fc2a34;
    margin: 0;
    cursor: pointer;
    -webkit-transform: none;
    -ms-transform: none;
    text-align: center;
    line-height: 64px;
    font-size: 17px;
    display: block !important;
    -webkit-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
}

.container-footer-slider  .icon-chevron-left
{
    left: 0px;
}

.container-footer-slider  .icon-chevron-right
{
    right: 0px;
}

@media (max-width: 1024px) {
    .container-footer-slider .icon-chevron-left,
    .container-footer-slider .icon-chevron-right {
        display: none !important;
    }

}

.product-info-left .product-images-panel h3 a {
    color: #294352;
}

.hire-tab-v2 .hire-tab-nav {
    display: flex;
    flex-wrap: wrap;
}

.hire-tab-v2 .hire-tab-nav .hire-tab-wrapper {
    margin-top: 10px;
}

.hire-tab-v2 .hire-tab-nav a {
    margin: 0 18px;
    padding-top: 5px;
}

.hire-tab-v2 .hire-tab-nav a.active {
    border-top: 2px solid #ff1520;
}

.product-grid-section.section-container .container .section-content-wrapper .product-container .product-content .product-content-right .product-content-right-inner .sales-info .sales-info-right {
    padding-right: 15px;
}

.location-two-col .location-two-col-inner .location-two-col-item {
    justify-content: flex-start;
}

.why-hiring-section .why-container-wrapper .why-container-content .btn-primary {
    background: #ff1520;
}

.price-guide-img.shelter {
    width: 40% !important;
    -ms-flex: 0 0 40% !important;
    flex: 0 0 40% !important;
}

.removal-quote-section .removal-quote-inner .removal-quote-details {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    color: #fff;
}

.price-table .table-responsive .white-table .house-size .house-size-content.hire-page {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
}

.removal-quote-section.removal-landing-page {
    padding: 40px 0px 30px 0px;
}

.all-dimension-table table .highlight {
    font-weight: 700;
}

.container-dimensions-inner {
    max-width: 1000px;
    margin: 0 auto;
}

.ebook-content-wrapper.homepage,
.ebook-content-wrapper.location-page {
    width: 65% !important;
    -ms-flex: 0 0 65% !important;
    flex: 0 0 65% !important;
}

.ebook-content-wrapper.homepage .btn-find-out-more,
.ebook-content-wrapper.location-page .btn-find-out-more {
    float: right;
    margin-right: 30px;
}

.masonary-image .grid-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;

    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
}

.home-price-guide .price-guide-wrapper .price-guide-form-section .price-guide-form .form-row textarea {
    color: #294354;
}

.testimonial.about-us {
    margin-top: 20px;
    margin-bottom: 20px;
}

.section-container.gallery-load-more {
    text-align: center;
}

.section-container.gallery-load-more .btn-load-more {
    max-width: 300px;
    /*text-align: center;*/
    background-color: #0006ca;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.section-container.gallery-load-more .btn-load-more img {
    height: 20px;
    width: auto;
    margin-left: 10px;
}

@media (max-width: 1024px) {
    .home-purpose-section.modifications .container {
        padding: 0 20px;
    }
}

@media (max-width: 767px) {
    .home-why-use-section .circle-items .circle-wrapper {
        padding: 0 20px 20px 20px;
    }
}

@media (max-width: 1024px) {
    .home-container .product-panel .product-anchor a {
        margin-bottom: 5px;
    }

    .breadcrumb {
        margin-top: 2px;
    }

    .breadcrumb li {
        margin-bottom: 0px;
        padding: 10px 0 6px 8px;
    }

    .new-home-leader-bottom .container {
        padding-bottom: 40px;
    }

    .home-removal-section .removal-links ul {
        padding: 0px 20px;
    }
}

@media (max-width: 600px) {
    .new-product-info-container .product-image-holder img {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
}

/* blog */
.content-area.single-article .content {
    margin-right: 50px;
}

@media (max-width: 600px) {
    .why-hiring-section .why-container-content {
        display: flex;
        flex-direction: column;
    }

    .why-hiring-section .why-container-content .btn-primary {
        align-self: center;
    }

    .mod-option .container {
        padding: 0 20px;
    }

    .mod-option .mod-grid .mod-block {
        padding: 40px 0;
    }

    .mod-option .mod-grid .mod-block .mod-block-footer .btn-primary {
        margin-top: 10px;
    }

    .delivery-methods .product-ticks.delivery ul li > div {
        flex-direction: column;
    }

    .delivery-methods .product-ticks.delivery ul li .value {
        margin-left: 17px;
        margin-top: 5px;
    }

    .national__coverage .national__coverage__content .right ul li {
        position: relative;
        margin-left: 20px;
    }

    .national__coverage .national__coverage__content .right ul li:before {
        /*content: url(/build/images/map_icon.552f4e49.svg);*/
        position: absolute;
        top: -4px;
        left: -36px;
    }

    #modSlider > .slick-list > .slick-track > .slick-slide,
    .brochure-slick-wrapper .slick-slide {
        height: 0px!important;
    }
    #modSlider > .slick-list > .slick-track > .slick-slide.slick-active,
    .brochure-slick-wrapper .slick-slide.slick-active {
        height: 100% !important;
    }

    .brochure-slick-wrapper .brochure_card {
        width: 100%;
        -webkit-box-shadow: 0px 0px 20px 2px rgb(0 0 0 / 16%);
        box-shadow: 0px 0px 20px 2px rgb(0 0 0 / 16%);
        padding: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 0px;
    }

    .brochure-slick-wrapper .brochure_card .card_content {
        margin-top: 30px;
        padding-bottom: 20px;
    }

    .brochure-slick-wrapper .brochure_card .card_content p {
        margin-bottom: 20px;
    }

    #modSlider .slick-dots {
        display: none !important;
    }

    .mod-slider #modSlider .slick-arrow {
        display: block !important;
    }

    .mod-slider #modSlider .slick-prev {
        left: -20px;
    }

    .mod-slider #modSlider .slick-next {
        right: -20px;
    }

    .hero-section.delivery .hero-lead-inner .hero-lead-left .hero-slider {
        padding-top: 50px;
    }

}

@media (min-width: 601px) {
    .mod-slider #modSlider .slick-arrow {
        display: none !important;
    }
}

.home-price-guide.shelter-price-guide h2 {
    font-size: 40px !important;
}

/* responsive changes from Steve @ last day */
@media (max-width: 600px) {
    .hero-section .hero-lead-inner .hero-lead-left .hero-content-wrap {
        padding: 10px 0;
    }

    .location-cta-row .location-cta-inner {
        text-align: center;
    }

    .home-price-guide .price-guide-wrapper .price-guide-form-section h2 {
        font-size: 30px;
    }

    .home-price-guide .price-guide-wrapper {
        padding-bottom: 0px;
    }

    .home-purpose-section.modifications .home-purpose-section-footer {
        display: none;
    }

    .home-removal-section .circle-items .circle-item {
        width: 100px;
        height: 130px;
    }

    .home-removal-section .circle-items .circle-item .label {
        top: 80%;
    }
    .home-removal-section .circle-items .circle-item .label {
        font-size: 12px;
        line-height: 15px;
    }

    .ebook-content-wrapper.homepage .btn-find-out-more, .ebook-content-wrapper.location-page .btn-find-out-more {
         float: inherit;
         margin-right: 0px;
    }

    .container-footer-slider .container-fullwidth {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .home-why-use-section {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    ontainer-types-wrapper {
        padding-bottom: 0px;
    }

    .container-types-wrapper .section-heading-wrap {
        padding-top: 10px;
    }

    .states-wrapper.shipping-location-location {
        margin-top: 0px;
    }

    .footer-slider-wrapper {
        margin-bottom: 0px;
    }

    .container-types-wrapper {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .home-price-guide {
        margin-top: 0px;
    }

    .location-cta-row {
        margin-bottom: 0px;
    }

    .home-purpose-section.modifications .circle-nav {
        margin-bottom: 0px;
    }

    .home-purpose-section.modifications .purpose-carousel .carousel-item-new-inner {
        margin-top: 0px;
    }

    .hire-section.section-container .section-heading-wrapper .section-left-placeholder-wrapper {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 23%;
        flex: 0 0 23%;
        overflow: hidden;
        display: none;
    }

    .hire-section.section-container .section-heading-wrapper .section-right-wrapper {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }

    .hire-section.section-container .section-heading-wrapper .section-right-wrapper h2 {
        text-align: center !important;
    }

    .hire-section.section-container .section-heading-wrapper .section-right-wrapper h2 {
        float: inherit;
    }

    .hire-section.section-container {
        padding-top: 50px;
    }

    .fullwidth .container .content-wrapper {
        padding-top: 40px;
    }

    .home-removal-section.location-links {
        padding-bottom: 0px;
    }

    .footer-slider-wrapper {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-right .bottom-wrapper .download-link-wrapper {
        /*width: 100%;*/
    }

    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-right .bottom-wrapper .image-wrapper {
        /*width: 100%;*/
    }

    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-right .bottom-wrapper {
        flex-wrap: wrap;
    }

    .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-right .bottom-wrapper .image-wrapper {
        margin-top: 30px;
    }

   .home-purpose-section .purpose-carousel .carousel-item .carousel-item-new-inner .carousel-item-inner-tab .carousel-tab-content li .carousel-tab-right .bottom-wrapper .image-wrapper img {
        transform: translateX(-5%);
        transform: translatey(-15px);
    }


}


.home-price-guide.modification-brochure .price-guide-wrapper .price-guide-img img {
    top: 51%;
}

/* blog */
.content-area .widget-block {
    margin-bottom: 0px;
}

.pager.pagination .page-item.active,
.pager.pagination .page-item.disabled {
    transform: translateY(10px);
    font-weight: bold;
    color: #ff1520;
}

.pager.pagination .page-item.disabled .icon-chevron-left {
    margin-right: 8px;
}

.pager.pagination .page-item.disabled .icon-chevron-right {
    margin-left: 8px;
}

@media (max-width: 600px) {
    .content-area .widget-area {
        padding-bottom: 0px;
    }

    .content-area h2 .article-heading {
        font-size: 22px;
    }

    .content-area .pager a {
        padding: 2px;
    }

    .content-area .pager.pagination .page-item.active, .pager.pagination .page-item.disabled {
        transform: translateY(0px);
    }
}

.removal-links .find-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.container.location-delivery-btn-wrapper {
    text-align: center;
    margin-top: 15px;
}

.container.location-delivery-btn-wrapper .btn-location-delivery {
    background-color: #0006ca;
    margin: 0 auto;
    display: flex;
    max-width: 250px;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.container.location-delivery-btn-wrapper .btn-location-delivery img {
    height: 50px;
    width: auto;
    margin-left: 7px;
}

.gallery .grid-item .image-index {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #0006ca;
    color: #ffffff;
    transform: translateY(-30px);
}

.gallery-load-more.delivery {
    margin-bottom: 30px;
}

.gallery-masonary-image .grid-item .gallery-title {
    font-size: 20px;
    font-weight: 600;
}

@media (min-width: 700px) {
    .gallery-masonary-image .grid-item.higher .description-wrapper {
        height: 200px;
        overflow-y: auto;
    }
}

.what-to-do-section .gallery-masonary-image .grid-item.higher {
    margin-bottom: 25px;
    /*margin-top: 25px;*/
    padding: 50px;
    width: calc(33.33% - 17px);
    background-color: rgb(194, 214, 195);
}

.what-to-do-section .gallery-masonary-image .grid-item a img {
    border-radius: 10px;
    box-shadow: 0px 10px 25px 0px rgb(0 0 0 / 40%);
}

.gallery .grid-item .image-index {
    border-bottom-left-radius: 10px;
}

.gallery .filter-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 30px;

}

.gallery .filter-wrapper label {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 500;
}

a.back-link {
    display: inline-block;
    margin-top: 10px;
}

/* community section */
.community-footer {
    width: 100%;
    background-color: #efecff;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 40px;
}

.community-footer .footer-contact-wrapper .heading {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: inherit;
    font-size: 40px;
    font-weight: 700;
    margin: 0 auto;
    margin-bottom: 30px;
}

.community-footer .contacts-wrapper {
    width: 100%;
    /*background-color: #c0c0c0;*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.community-footer .contacts-wrapper .contact-wrapper {
    width: 33.33%;
    /*border: 1px #111111 solid;*/
}

@media (max-width: 1200px) {

    .community-footer .contacts-wrapper .contact-wrapper {
        width: 100%;
    }

}

.community-footer .contacts-wrapper .contact-wrapper .title,
.community-footer .contacts-wrapper .contact-wrapper .contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.community-footer .contacts-wrapper .contact-wrapper .title {
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 700;
}

.community-footer .contacts-wrapper .contact-wrapper .contact {
    font-weight: 700;
    font-size: 20px;
}

.community-footer .contacts-wrapper .contact-wrapper .contact a {
    color: #294352;
}

@media (max-width: 400px) {

    .community-footer .contacts-wrapper .contact-wrapper .contact a {
        font-size: 15px;
    }
}

.community-footer .contacts-wrapper .contact-wrapper .contact .social {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 0px;
}

.community-footer .footer-logo-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    padding-left: 100px;
    padding-right: 100px;
}


@media (max-width: 600px) {
    .community-footer .footer-logo-wrapper {
        margin-bottom: 40px;
    }

}

.community-footer .logo-wrapper {
    height: 80px;
    /*margin-right: 70px;*/
    margin-bottom: 50px;
}

.community-footer .logo-wrapper img {
    height: 80px;
    margin-right: 30px;
    margin-bottom: 50px;
}

.community-footer .logo-wrapper img:last-of-type {
    margin-right: 0px;
}

@media (max-width: 600px) {
    .community-footer .contacts-wrapper .contact-wrapper .contact {
        font-size: 20px;
    }

    .community-footer .contacts-wrapper .contact-wrapper .title {
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .community-footer .footer-contact-wrapper .heading {
        margin-bottom: 0px;
    }

    .community-footer .logo-wrapper {
        margin: 0 auto;
        margin-bottom: 30px;
    }

    .community-footer .footer-logo-wrapper {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.community-criteria .header {
    font-size: 26px;
    font-weight: 700;
    color: #672879;
}

.community-criteria .intro,
.community-criteria .note {
    font-size: 15px;
    color: #672879;
    width: 65%;
    margin-bottom: 30px;
}

.community-criteria .intro {
    width: 100% !important;
    margin-top: 20px !important;
}

.community-criteria .note {
    margin-top: 20px;
}

.community-criteria .box-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.community-criteria .box-wrapper .box {
    width: 32%;
    height: 150px;
    background-color: #efecff;
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 25px 20px;
    font-size: 15px;
    line-height: 30px;
    color: #0d0149;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.community-criteria .box-wrapper .box.display-none {
    background-color: inherit;
}

@media (min-width: 701px) and (max-width: 1200px) {
    .community-criteria .box-wrapper .box {
        width: 49%;
    }
    .community-criteria .box-wrapper .box.display-none {
        display: none;
    }

}

@media (max-width: 700px) {
    .community-criteria .box-wrapper .box {
        width: 100%;
        font-size: 22px;
        line-height: 35px;
    }
    .community-criteria .box-wrapper .box.display-none {
        display: none;
    }

    .community-criteria .intro,
    .community-criteria .note {
        width: 100%;
    }

    .community-criteria .note {
        margin-top: 10px;
    }


    .community-criteria .header {
        font-size: 32px;
    }

}

.community-application {
    background-color: #b993c3;
    margin-bottom: 50px;
    padding-bottom: 30px;
}

.community-application-wrapper {
    margin-left: 15%;
    margin-right: 15%;
}

@media (max-width: 500px) {
    .community-application-wrapper {
        margin-left: 5%;
        margin-right: 5%;
    }
}

.community-application .header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: 30px;
}

.community-application .header-wrapper .header .title {
    font-size: 30px;
    font-weight: 700;
    color: #672879;
    text-transform: uppercase;
}

.community-application .header-wrapper .header .sub-title {
    font-size: 30px;
    font-weight: 700;
    color: #672879;
    text-transform: uppercase;
    margin-left: 7px;
}




@media (min-width: 691px) and (max-width: 1000px) {
    .community-application .header-wrapper .header .title,
    .community-application .header-wrapper .header .sub-title {
        font-size: 20px;
    }

    .community-application .header-wrapper {
        justify-content: center;
    }
}

@media (max-width: 690px) {
    .community-application .header-wrapper .header .title,
    .community-application .header-wrapper .header .sub-title {
        font-size: 14px;
    }
}



@media (max-width: 700px) {
    .community-application .box .step-arrow {
        width: 0px !important;
    }
}

.community-application .box-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.community-application .box {
    width: 20%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

@media (min-width: 701px) and (max-width: 1100px) {
    .community-application .box {
        width: 33.33%;
    }
}

@media(max-width: 700px) {
    .community-application .box {
        width: 100%;
    }
}


.community-application .box .step-wrapper {
    width: 100%;
}

.community-application .box .step-arrow {
    width: 20px;
    position: relative;
}

.community-application .box .step-arrow img {
    width: 20px !important;
    position:absolute;
    top: 22%;
}

.community-application .box:last-of-type .step-arrow {
    display: none;
}

.community-application .box:last-of-type .step-arrow {
    display: none;
}

.community-application .box .icon {
    width: 155px;
    margin: 0 auto;
}

.community-application .box .step {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #ffffff;
    color: #672879;
    font-size: 18px;
    font-weight: 700;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.community-application .box .title {
    color: #672879;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}

.community-introduction {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    background-color: #efecff;
    padding: 50px 80px;
}

@media (max-width: 600px) {
    .community-introduction {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.community-introduction .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
    padding-right: 50px;
}

.community-introduction .right {
    width: 30%;
}

@media (max-width: 1000px) {
    .community-introduction .left {
        width: 100%;
        padding-right: 0px;
    }

    .community-introduction .right {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;
    }

}


.community-introduction .right img {
    border-radius: 10px;
}

.community-introduction .left .top {
    font-size: 18px;
    line-height: 22px;
}

.community-introduction .left .bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.community-introduction .left .bottom .button {
    width: 48%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    background-color: #ffffff;
    border-radius: 10px;
    padding:20px 50px;
}

@media (max-width: 600px) {
    .community-introduction .left .bottom .button {
        padding:10px 20px;
    }
}

.community-introduction .left .bottom .button:hover {
    cursor: pointer;
}

.community-introduction .left .bottom .button,
.community-introduction .left .bottom .button a {
    font-size: 18px;
    font-weight: 600;
    color: #672879;
}

@media (max-width: 600px) {
    .community-introduction .left .bottom .button,
    .community-introduction .left .bottom .button a {
        font-size: 14px;
    }

}

.community-support {
    margin-top: 100px;
    margin-bottom: 50px;
}

@media (max-width: 700px) {
    .community-support {
        margin-top: 50px;
    }
}

.community-support .support-features-wrapper {
    padding-left: 10%;
    padding-right: 10%;
}

@media (max-width: 700px) {
    .community-support .support-features-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
}

.community-support .support-features-wrapper .link:hover {
    cursor: pointer;
}

.community-support .heading,
.community-application-form .heading {
    font-size: 80px;
    font-weight: 700;
    color: #672879;
    padding-left: 10px;
    margin-bottom: 30px;

}

.community-support .heading.small,
.community-application-form .heading.small {
    font-size: 26px;
    padding-left: 0px;
    margin-bottom: 30px;
}

@media (max-width: 1200px) {
    .community-support .heading {
        padding-left: 0px;
    }
}


@media (min-width: 601px) and (max-width: 1000px) {
    .community-support .heading,
    .community-application-form .heading {
        font-size: 50px;
    }
}

@media (max-width: 600px) {
    .community-support .heading,
    .community-application-form .heading {
        font-size: 32px;
        margin-bottom: 20px;
    }
}

.community-support .box-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.community-support .box-wrapper .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 24%;
}

@media (max-width: 600px) {
    .community-support .box-wrapper .box {
        width: 49%;
    }

}

.community-support .box-wrapper .box img {
    width: 100%;
    border-radius: 10px;
}

.community-support .box .link {
    background-color: #b993c3;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 15px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media (max-width: 1100px) {
    .community-support .box .link {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.community-support .box .link a {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 15px;
}

@media (max-width: 800px) {
    .community-support .box .link a {
        font-size: 12px;
    }
}

.support-slider-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 20px;
}

.support-slider-wrapper .slick-list {
    padding:0 20% 0 0 !important;
}

.support-slider-wrapper .support-box {
    width: 55vw;
    margin-right: 100px;
    opacity: 0.4;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.support-slider-wrapper .support-box.slick-current {
    opacity: 1.0;
}

.support-slider-wrapper .support-box .body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
}

.support-slider-wrapper .support-box .body .left {
    width: 65%;
    padding-right: 20px;
}

.support-slider-wrapper .support-box .body .left .content {
    font-size: 15px;
    line-height: 22px;
    /*max-height: 250px;*/
    /*overflow-y: scroll;*/
}

.support-slider-wrapper .support-box .body .right {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

@media (max-width: 700px) {
    .support-slider-wrapper .support-box .body .left {
        width: 100%;
        padding-right: 0px;
    }
    .support-slider-wrapper .support-box .body .right {
        width: 100%;
        margin-bottom: 20px;
    }
    .support-slider-wrapper .support-box .body .left .content {
        font-size: 14px;
    }
    .support-slider-wrapper .support-box {
        margin-right: 30px;
    }

}


.support-slider-wrapper .support-box .body .right .images-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.support-slider-wrapper .support-box .body .right .image {
    width: 48%;
}

.support-slider-wrapper .support-box .body .right .next-button {
    background-color: #0d0149;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 12px;
    padding: 15px 20px;
    border-radius: 10px;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    margin-top: 15px;
}

.support-slider-wrapper .support-box .body .right .next-button:hover {
    cursor: pointer;
}

.support-slider-wrapper .support-box .body .right .next-button img {
    width: 10px;
    margin-left: 6px;
}

.support-slider-wrapper .support-box .body .right .image img {
    border-radius: 10px;
}

.support-slider-wrapper .support-box .body .left .social-media {
    margin-top: 50px;
}

.support-slider-wrapper .support-box .body .left .social-media .header {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #0d0149;
    margin-bottom: 10px;
}

.support-slider-wrapper .support-box .body .left .social-media .icons-wrapper .icon {
    font-size: 20px;
    margin-right: 15px;
}

.community-application-form {
    margin-top: 100px;
    margin-bottom: 60px;
    padding-left: 5%;
    padding-right: 5%;
}

@media (max-width: 700px) {
    .community-application-form {
        margin-top: 50px;
    }
}

.community-application-form .heading {
    padding-left: 0px;
    margin-bottom: 5px;
}

.community-application-form .sub-title {
    font-size: 20px;
    color: #672879;
    margin-left: 6px;
}

.community-application-form .body {
    margin-top: 30px;
}

.community-application-form .form-line.two-columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.community-application-form .form-line {
    font-size: 15px;
    color: #672879;
    margin-left: 6px;
    margin-bottom: 20px;
}

.community-application-form .form-line.two-columns .left,
.community-application-form .form-line.two-columns .right {
    width: 48%;
}


@media (max-width: 1000px) {
    .community-application-form .form-line.two-columns .left,
    .community-application-form .form-line.two-columns .right {
        width: 100%;
    }

    .community-application-form .form-line.two-columns .right {
        margin-top: 20px;
    }
}

.community-application-form #criteria-link {
    text-decoration: underline;
}

.community-application-form #criteria-link a {
    font-size: 18px;
    color: #ffffff;
}

.community-application-form #criteria-link:hover {
    cursor:pointer;
}

.community-application-form .form-line.single-column label {
    width: 200px;
}

.community-application-form .form-line label {
    display: inline-block;
}

.community-application-form .form-line.normal-label label {
    width: 150px;
}

.community-application-form .form-line.normal-label input {
    width: calc(100% - 150px);
}

.community-application-form .form-line.long-label label {
    width: 250px;
}

.community-application-form .form-line.long-label input {
    width: calc(100% - 250px);
}

.community-application-form .form-line.longer-label label {
    width: 400px;
}

.community-application-form .form-line.longer-label input {
    width: calc(100% - 400px);
}

@media (max-width: 700px) {
    .community-application-form .form-line.normal-label label {
        width: 100%;
    }

    .community-application-form .form-line.normal-label input {
        width: 100%;
    }

    .community-application-form .form-line.long-label label {
        width: 100%;
    }

    .community-application-form .form-line.long-label input {
        width: 100%;
    }

    .community-application-form .form-line.longer-label label {
        width: 100%;
    }

    .community-application-form .form-line.longer-label input {
        width: 100%;
    }

}

@media (max-width: 480px) {
    #criteria-link {
        display: inline-block;
        margin-top: 10px;
    }
}

.community-application-form #comment {
    width: 100%;
    height: 200px;
}
.community-application-form #apply-before {
    width: 100%;
    height: 50px;

}

.community-application-form .form-line.submit {
    display: flex;
    justify-content: center;
    align-items: center;
}

.community-application-form .form-line.submit input {
    background-color: #672879;
    color: #ffffff;
    text-transform: uppercase;
    padding: 10px 100px;
    border-radius: 5px;
    font-size: 18px;
    border: 0px;
}

.community-application-form input[type="text"],
.community-application-form input[type="email"],
.community-application-form textarea {
    border-radius: 5px;
}

.community-application-form textarea:focus,
.community-application-form input:focus{
    outline: none;
}

/* Custom checkbox */
.custom-checkbox-wrapper {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 10px;
    width: 10px;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: -12px;
    left: 10px;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 1px solid #999;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-checkbox-wrapper input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox-wrapper .checkmark:after {
    left: 11px;
    top: 4px;
    width: 12px;
    height: 20px;
    border: 1px solid #999;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.community-application-form .thanks-content {
    font-size: 30px;
    font-weight: 700;
    color: #672879;
}

.community-application-form #amount-requested {
    max-width: 280px;
}

.community-application-form .section-headings {
    font-size: 18px;
    font-weight: 700;
    color: #672879;
}

.community-application-form .required {
    color: #ff1520;
    font-weight: 700;
}

.home-container h2.lg a {
    color: #294352;
}

.pricing-container .pricing-link,
.dimension-container .dimension-link {
    display: flex;
    justify-content: right;
    margin-top:10px;
}

@media (max-width: 1230px) {
    header .secondary-header .secondary-header-inner .secondary-header-right .secondary-header-nav li {
        padding: 12px 10px;
    }
}


.product-grid-section.section-container .container .section-content-wrapper.products-filter-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
}

.product-grid-section.section-container .container .section-content-wrapper.products-filter-wrapper .filter {
    margin-left: 50px;
}

.product-grid-section.section-container .container .section-content-wrapper.products-filter-wrapper .filter .label {
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
    color: #294352;
    margin-bottom: 10px;
}

.product-grid-section.section-container .container .section-content-wrapper.products-filter-wrapper .filter.type {
    width: 200px;
}

.dimension-container .table-responsive .dimension-table tr.dummy td {
    width: 16.66666666666667%;
    padding-top: 0px;
    padding-bottom: 0px;
}

.footer .container-types-wrapper .section-heading-wrap h2 a {
    color: #FFFFFF;
}

.footer .container-sizes-wrapper h2 a {
    color: #294352;
}

.new-home-leader-bottom-2 .container ul {
    text-align: left;
    margin-left: 50px;
}

.new-home-leader-bottom-2 .container ul a {
    color: #294352;
}

#product-grid .product-container.hidden {
    display: none;
}

#price-guide .pricing-table tbody tr.hidden {
    display: none;
}

#product-grid-new .product-content-left .product-form {
    display: none;
}

#product-grid-used .product-content-left .product-form {
    display: none;
}

.home-purpose-section .circle-nav-wrapper {
    border-top: 1px solid #c4cfd6;
    padding-top: 20px;
}

.home-purpose-section .circle-nav-wrapper .circle-nav {
    margin-bottom: 0px;
}

.home-purpose-section .circle-nav-wrapper .circle-nav-arrows {
    width: 30px;
    top: 67% !important;
}

.home-purpose-section .circle-nav-wrapper .circle-nav-item-inner {
    display: block;
    border: 1px solid #c4cfd6;
    border-radius: 10px;
    width: 90%;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.home-purpose-section .circle-nav-wrapper .circle-nav-item-inner.active {
    border: 1px solid #ff1520;
}

.home-purpose-section .circle-nav-wrapper .circle-nav-item-inner p {
    margin-top: 0px !important;
}

.home-purpose-section .circle-nav-wrapper .circle-nav-item-inner p svg {
    stroke: #294352;
    transform: translateY(8px);
    margin-right: 5px;
}
.home-purpose-section .circle-nav-wrapper .circle-nav-item-inner.active p svg {
    stroke: #ff1520;
}

.dimension-container .dimension-list .dimension-table .cell-label {
    display: none;
    width: 0px;
    padding: 0px;
    background-color: #f2f2f2;
}

.dimension-container .dimension-list .dimension-table .cell-value {
    display: inline-block;
    width: 100%;
}

@media (max-width: 1100px) {
    .dimension-container .dimension-list .dimension-table .cell-label {
        display: inline-block;
        width: 49%;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .dimension-container .dimension-list .dimension-table .cell-value {
        display: inline-block;
        width: 49%;
    }

    .dimension-container .table-responsive .dimension-table td:not(:first-child) {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        padding-left: 0px !important;
    }

}

.circle-nav-wrapper.v1 .circle-nav-item-outter {
    cursor: pointer;
}

.megamenu-wrapper {
    width: 100%;
}

.megamenu-wrapper .container-types {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    list-style: none;
    margin-top: 70px;
    margin-bottom: 70px;
    justify-content: space-between;
}

.megamenu-wrapper.mobile .container-types {
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 0px;
}

.megamenu-wrapper .container-types li {
    width: 15%;
    text-align: center;
}

.megamenu-wrapper.mobile .container-types li {
    width: 50%;
    text-align: center;
}

.megamenu-wrapper .container-types li a {
    color: #294352;
}

.megamenu-wrapper .container-types li img {
    width: auto;
    /*height: 150px;*/
    height: auto;
    aspect-ratio: 1.5;
}

.megamenu-wrapper .container-types li h3:hover {
    text-decoration: underline;
}

.megamenu-wrapper.mobile .sizes {
    width: 100%;
    border-bottom: 1px solid var(--mm-color-border);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    list-style: none;
    padding: 10px 20px;
    justify-content: space-between;
}

.megamenu-wrapper.mobile .sizes a {
    text-decoration: underline;
}

.usc-content-block.hire .megamenu-wrapper .container-types .hire-rate,
.shipping-containers.hire  .container-types .hire-rate
{
    color: #ff1520;
    text-decoration: underline;
}

.used-shipping-containers .usc-content .usc-content-block.hire.left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 68%;
    flex: 0 0 68%;
    width: 68%;
}

.used-shipping-containers .usc-content .usc-content-block.hire.right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 28%;
    flex: 0 0 28%;
    width: 28%;
}

.usc-content-block.hire .megamenu-wrapper .container-types li {
    width: 25%;
    text-align: center;
}


#mobile-menu ul li a {
    text-decoration: underline;
}

#mobile-menu .mm-navbars_top .mm-navbar a.mm-navbar__title {
    text-decoration: underline;
}

.mobile-sticky-footer {
    display: none;
    height: 95px;
    background-color: #f5f5f5;
    text-align: center;
    line-height: 20px;
    position: sticky;
    bottom: 0;
    z-index:99;
    background-color: #294352;
    padding: 10px 30px;
}

@media (max-width: 600px) {
    .mobile-sticky-footer {
        display: block;
    }
}

.mobile-sticky-footer .icons-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 100%;
}

.mobile-sticky-footer .icons-wrapper .icon {
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    color: #ffffff;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top:10px;
    padding-bottom: 10px;
}

.mobile-sticky-footer.homepage .icons-wrapper .icon {
    width: 31%;
}

.mobile-sticky-footer.inner-page .icons-wrapper .icon {
    width: 31%;
}

.mobile-sticky-footer.homepage .price,
.mobile-sticky-footer.homepage .size {
    display: none;
}

.mobile-sticky-footer.homepage .home,
.mobile-sticky-footer.homepage .call,
.mobile-sticky-footer.homepage .quote {
    display: flex;
}

.mobile-sticky-footer.inner-page .price,
.mobile-sticky-footer.inner-page .size {
    display: none;
}

.mobile-sticky-footer.inner-page .home,
.mobile-sticky-footer.inner-page .call,
.mobile-sticky-footer.inner-page .quote {
    display: flex;
}

.mobile-sticky-footer .icons-wrapper .icon a {
    color: #ffffff;
}

.mobile-sticky-footer .icons-wrapper .icon img {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
}

.product-grid-section .section-heading-wrapper .inner-navigation,
.sticky-wrapper .inner-navigation {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin-top: 20px;
    border-bottom: 2px solid #ff1520;
    padding-bottom: 15px;
}

.product-grid-section .section-heading-wrapper .inner-navigation .menu,
.sticky-wrapper .inner-navigation .menu {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    padding: 0px 60px;
}

.product-grid-section .section-heading-wrapper .inner-navigation .menu:not(:last-child),
.sticky-wrapper .inner-navigation .menu:not(:last-child) {
    border-right: 1px solid rgb(41, 67, 82);
}

@media (max-width: 700px) {
    .product-grid-section .section-heading-wrapper .inner-navigation,
    .sticky-wrapper .inner-navigation {
        /*flex-direction: column;*/
    }
    .product-grid-section .section-heading-wrapper .inner-navigation .menu:not(:last-child),
    .sticky-wrapper .inner-navigation .menu:not(:last-child) {
        /*border-right: 0px solid rgb(41, 67, 82);*/
    }
    .product-grid-section .section-heading-wrapper .inner-navigation .menu,
    .sticky-wrapper .inner-navigation .menu {
        padding: 0px 10px;
    }

    .product-grid-section .section-heading-wrapper .inner-navigation,
    .sticky-wrapper .inner-navigation {
        padding-left: 0px;
    }

}


.red-link {
    color: #ff1520;
    text-decoration: underline;
}

.price-guide-table .quote-me {
    margin-top: 10px;
}

.price-guide-table .location-cta-inner p {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@media (max-width: 700px) {
    .price-guide-table .location-cta-inner p {
        /*flex-direction: column;*/
    }

}

.product-grid-section .section-heading-wrapper .inner-navigation a,
.sticky-wrapper .inner-navigation a {
    color: #294352;
    text-decoration: underline;
}

@media (max-width: 600px) {
    .hero-section.hire-page .hero-lead-inner .home-lead-right {
        display: none !important;
    }
    .hero-section.hire-page::after {
        height: 300px !important;
    }

    .new-home-leader-bottom.hire-page .block-item h3 {
        border-right: 1px solid #ff1520;
    }

    .new-home-leader-bottom.hire-page .block-item:first-of-type h3 {
        border-right: 0px solid #ff1520;
    }

    .new-home-leader-bottom.hire-page .block-item p {
        display: none;
    }

    .new-home-leader-bottom.hire-page .block-item h3 {
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .new-home-leader-bottom.hire-page .new-home-leader-bottom-content {
        flex-direction: row-reverse;
        flex-wrap: nowrap;
    }

    .new-home-leader-bottom.hire-page .new-home-leader-bottom-content .block-item {
        flex: 0 0 auto;
        width: auto;
    }

    #review-container-top.hire-page .review-module-mobile-bottom {
        display: none;
    }

    .new-home-leader-bottom-2.hire-page .container {
        margin-top: 0px;
    }

    .container-tab-wrapper.hire-use-case .hire-tab-wrapper {
        display: none;
    }
}

.container.two-columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.container.two-columns .left,
.container.two-columns .right {
    width: 50%;
    padding-right: 40px;
}

.container.two-columns .title {
    width: 100%;
    font-weight: 800;
    font-size: 25px;
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

@media (max-width: 600px) {
    .container.two-columns .left,
    .container.two-columns .right {
        width: 100%;
        padding-right: 0px;
    }
}

.sticky-wrapper {
    display: none;
    margin-top: 10px;
}

.sticky-wrapper.sticky {
    display: block;
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0; /* Stick to the top */
    background-color: #ffffff;
    z-index: 1000; /* Ensures it stays above other content */
}

.sticky-wrapper .inner-navigation {
    padding-top: 7px;
}



